import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import useColors from "../../hooks/useColors";

import { IFlow } from "../../../functions/src/types/flowType";
import MenuComponent from "./MenuComponent";

export function ItemComponent({
    item,
    handleClick,
    selected,
}: {
    item: IFlow;
    handleClick: any;
    selected: boolean;
}) {
    const [typographyWidth, setTypographyWidth] = useState<number | "auto">("auto");

    const [showDeleteCaseDialog, setShowDeleteCaseDialog] = useState<boolean>(false);

    const handleCancelDeleteCase = () => {
        setShowDeleteCaseDialog(false);
    };

    const containerRef = useRef<HTMLDivElement>(null);

    const colors = useColors();

    useEffect(() => {
        const calculateWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const iconButtonWidth = 52; // Adjust this based on actual IconButton size
                const calculatedWidth = containerWidth - iconButtonWidth - 8 * 2; // Subtract IconButton width and margins
                setTypographyWidth(calculatedWidth);
            }
        };

        // Calculate on mount and window resize
        calculateWidth();
        window.addEventListener("resize", calculateWidth);

        // Cleanup
        return () => window.removeEventListener("resize", calculateWidth);
    });

    const messageToDisplay = () => {
        return item?.keywords?.join(" , ") || "";
    };

    const handleDeleteCase = async () => {
        if (!item?.id) {
            toast.error("Cannot delete case: Invalid case ID");
            return;
        }
    };

    const handleClickMenu = async (e: any, item: string) => {
        e.stopPropagation();
        if (item === "Delete Case") {
            await handleDeleteCase();
        }
    };

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            onClick={() => handleClick(item)}
            justifyContent={"space-between"}
            sx={{
                borderBottom: `1px solid ${colors.topBarLine}`,
                "&:hover": {
                    backgroundColor: colors.boxHighlight, // Replace 'primary.main' with your desired color
                },
                cursor: "pointer",
            }}
            p="4px"
        >
            <Box
                p="8px"
                display="flex"
                width={"100%"}
                ref={containerRef}
                sx={{ backgroundColor: selected ? colors.boxHighlight : "none" }}
            >
                <Box>
                    <Avatar sx={{ width: "45px", height: "45px" }} />
                </Box>
                <Box display="flex" flexDirection={"column"} p="0 8px 0 8px" width={"100%"}>
                    <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={`${typographyWidth}px`}
                    >
                        <Typography
                            variant="body1"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word", // Break long words
                            }}
                        >
                            {item?.name}
                        </Typography>
                        <Typography variant="caption" color="#71818a">
                            {item?.date ? formatDate(convertDate(item.date), true) : ""}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={`${typographyWidth}px`} // Set width to calculated width because nowrap only works with fixed width
                    >
                        <Typography
                            variant="body2"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word", // Break long words
                            }}
                        >
                            {messageToDisplay()}
                        </Typography>
                        {/* <IconButton size="small" onClick={(event) => handleClickMore(event, caseItem)}> */}
                        <IconButton size="small">
                            <MenuComponent
                                menuItems={["Delete Case"]}
                                type="dropdown"
                                onMenuItemClick={handleClickMenu}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Dialog open={showDeleteCaseDialog} onClose={handleCancelDeleteCase}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    This action cannot be undone. This will permanently delete the case and remove all
                    associated data.
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancelDeleteCase}>Cancel</Button>
                    <Button variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
