import {
    collection,
    doc,
    getDoc,
    onSnapshot,
    or,
    orderBy,
    query,
    Timestamp,
    where,
} from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { db } from "../../firebase/firebase-utils";
import { Case, Company, ICustomField, User } from "./CasesPage";
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowSelectionModel,
    GridToolbar,
} from "@mui/x-data-grid";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import convertDate from "../../utils-functions/convertDate";
import { format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { AuthContext } from "../../context/AuthContext";
import formatDate from "../../utils-functions/formatDate";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

interface CasesReportProps {
    caseItem?: Case;
    latestCompanyId?: string; // Made optional since it doesn't seem to be used
}

const CasesReport: React.FC<CasesReportProps> = ({ caseItem, latestCompanyId }) => {
    const [caseData, setCaseData] = useState<Case[] | undefined>([]);
    // Define a type for the filter options, including null
    type FilterOption = "date" | "agentName" | "value" | null;

    // Define state for the filtered item, initialized to null
    const { user } = useContext(AuthContext) as { user: User };
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const companyId = searchParams.get("companyId");

    const [companies, setCompanies] = useState<Company[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>("");

    // Change to use Dayjs type
    const [dateFrom, setDateFrom] = useState<Dayjs | null>(null);
    const [dateTo, setDateTo] = useState<Dayjs | null>(null);

    const [allAgents, setAllAgents] = useState<{ id: string; name: string }[]>([]);
    const [agents, setAgents] = useState<{ id: string; name: string }[]>([]);
    const [selectedAgent, setSelectedAgent] = useState<string>("");

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

    const handleBackClick = () => {
        if (companyId) {
            navigate("/cases-page", {
                state: { companyId },
                replace: true, // Use replace to prevent building up history stack
            });
        }
    };

    useEffect(() => {
        const today = dayjs(); // Use dayjs for today's date
        const pastDate = today.subtract(60, "day"); // Subtract 60 days using dayjs

        setDateFrom(pastDate);
        setDateTo(today);
        console.log("Past Date:", pastDate.format("YYYY-MM-DD")); // Format for readability
        console.log("Today:", today.format("YYYY-MM-DD")); // Format for readability
    }, []);

    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "companies");
        const q = query(collectionRef, where("admins", "array-contains", user.id));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            let companies = mapSnapshotTS<Company>(querySnapshot);

            const userCompanyIds = user.companyIds || [];

            const userCompanies = await Promise.all(
                userCompanyIds.map(async (companyId) => {
                    const docRef = doc(db, "companies", companyId);
                    const snapshot = await getDoc(docRef);
                    const company = mapDocSnapshot(snapshot);
                    return company;
                })
            );

            companies = [...companies, ...userCompanies];

            // Remove any null or undefined values
            companies = companies.filter(Boolean);

            // Remove duplicates based on id
            companies = companies.filter(
                (company, index, self) => index === self.findIndex((t) => t.id === company.id)
            );

            // order by name
            companies = companies.sort((a, b) => a.name.localeCompare(b.name));

            setCompanies(companies);
            console.log(companies);
        });

        return unsubscribe;
    }, [user]);

    // Extract initial companyId from URL query params
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const initialCompanyId = params.get("companyId");
        if (initialCompanyId) {
            setSelectedCompany(initialCompanyId);
        }
    }, [location.search]);

    // Handle company selection change
    const handleCompanyChange = (event: SelectChangeEvent<string>) => {
        const newCompanyId = event.target.value as string;
        setSelectedCompany(newCompanyId);

        // Update the URL with the selected companyId
        const params = new URLSearchParams(location.search);
        params.set("companyId", newCompanyId);
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });

        console.log("Selected Company ID:", newCompanyId);
    };

    const handleAgentChange = (event: SelectChangeEvent<string>) => {
        setSelectedAgent(event.target.value);

        console.log("Selected Agent ID:", event.target.value);
    };

    // Log currentCompanyId to ensure it's passed correctly
    useEffect(() => {
        console.log("Current Company ID:", companyId);
    }, [companyId]);

    // Reset selectedAgent when companyId changes
    useEffect(() => {
        setSelectedAgent(""); // or setSelectedAgent(""); depending on your preference
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            console.log("No currentCompanyId found, skipping Firestore query.");
            return;
        }

        // Reset caseData when companyId changes

        setCaseData([]);

        // First, check if user is an admin in the company
        const companyRef = doc(db, "companies", companyId);
        onSnapshot(companyRef, (companyDoc) => {
            if (!companyDoc.exists()) {
                console.log("Company document does not exist.");
                return;
            }

            const companyData = companyDoc.data();
            const isCompanyAdmin = companyData.admins?.includes(user.id);

            // Set up the cases query based on whether the user is a company admin
            if (dateFrom && dateTo) {
                const collectionRef = collection(db, "cases");
                let q = query(
                    collectionRef,
                    where("companyId", "==", companyId),
                    where("date", ">=", Timestamp.fromDate(dateFrom.toDate())),
                    where("date", "<=", Timestamp.fromDate(dateTo.toDate())),
                    orderBy("date", "desc")
                );

                let queries = [];

                if (isCompanyAdmin) {
                    // If the user is a company admin, query all cases for that company
                    queries = [q];
                } else {
                    // If not a company admin, filter cases by user’s admin role within the case
                    q = query(
                        q,
                        or(
                            where("agentIds", "array-contains", user.id),
                            where("viewerIds", "array-contains", user.id),
                            where("editorIds", "array-contains", user.id),
                            where("admins", "array-contains", user.id)
                        )
                    );
                }

                // Listen for changes to the cases data
                const unsubscribe = onSnapshot(q, (snapshot) => {
                    let casesData = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                        customFields: doc.data().customFields || [],
                    })) as Case[];

                    // define custom field in case data
                    casesData.forEach((caseItem) => {
                        if (caseItem?.customFields) {
                            caseItem.customFields.forEach((field) => {
                                (caseItem as any)[field.label] = field.value;
                            });
                        }
                    });

                    console.log("casesData", casesData);

                    // define custom field in case data
                    casesData.forEach((caseItem, index) => {
                        if (caseItem?.customFields) {
                            caseItem.customFields.forEach((field) => {
                                (caseItem as any)[field.label] = field.value;
                            });
                        }

                        // Add 'No' field to each row, based on the index
                        if (caseItem && !(caseItem as any).no) {
                            // Avoid overwriting if 'No' already exists
                            (caseItem as any).no = index + 1; // Index + 1 for 1-based index
                        }
                    });

                    setCaseData((prevData = []) => {
                        // Filter out any null or undefined caseItem from prevData before mapping
                        const uniqueCases = new Map(
                            prevData
                                .filter((caseItem) => caseItem != null)
                                .map((caseItem) => [caseItem.id, caseItem])
                        );

                        // Process the new casesData and merge
                        casesData.forEach((caseItem) => {
                            if (caseItem) {
                                uniqueCases.set(caseItem.id, caseItem);
                            }
                        });

                        return Array.from(uniqueCases.values());
                    });

                    // Process agent and label data as before
                    const uniqueAgents = new Set<string>();
                    const allAgentList: { id: string; name: string }[] = [];
                    casesData.forEach((caseItem) => {
                        caseItem?.agentIds?.forEach((agentId, index) => {
                            const agentName = caseItem.agentNames[index];
                            if (!uniqueAgents.has(agentId)) {
                                uniqueAgents.add(agentId);
                                allAgentList.push({ id: agentId, name: agentName });
                            }
                        });
                    });
                    console.log("uniqueAgents", allAgentList);

                    // Set the agent list to be used in UI or dropdown
                    setAgents(allAgentList);
                    console.log("agents list", allAgentList);

                    const uniqueLabels = new Set<string>();
                    casesData.forEach((caseItem) => {
                        caseItem?.customFields?.forEach((field) => {
                            uniqueLabels.add(field.label);
                        });
                    });

                    const dynamicColumns: GridColDef[] = Array.from(uniqueLabels).map((label) => ({
                        field: label,
                        headerName: label,
                        width: 150,
                        headerAlign: "center",
                        align: "center",
                        renderCell: (params: GridRenderCellParams) => {
                            const customField = (params.row.customFields || []).find(
                                (field: ICustomField) => field.label === label
                            );
                            return customField ? customField.value : "";
                        },
                    }));

                    setColumns((prevColumns) => {
                        const newDynamicColumns = dynamicColumns.filter(
                            (newCol) => !prevColumns.some((col) => col.field === newCol.field)
                        );
                        return [...prevColumns, ...newDynamicColumns];
                    });
                });

                // Clean up all snapshots on component unmount
                return unsubscribe;
            } else {
                console.log("No Date Range Set");
            }
        });
    }, [companyId, dateFrom, dateTo, user.id, selectedAgent]);

    // Define a function to format the Firestore timestamp
    const formatTimestamp = (timestamp: Timestamp | null) => {
        if (!timestamp) return ""; // Handle null or undefined timestamps
        const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
        return format(date, "yyyy-MM-dd"); // Format date as needed
    };

    // Define the columns for the DataGrid based on the Case fields
    const [columns, setColumns] = useState<GridColDef[]>([
        {
            field: "no",
            headerName: "No",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center", // Center the data
            width: 80,
            // renderCell: (params) => {
            //     // +1 to make it 1-based index
            //     return params.api.getRowIndexRelativeToVisibleRows(params.id) + 1;
            // },
        },
        {
            field: "date",
            headerName: "Date",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center", // Center the data
            width: 150,
            renderCell: (params) => params.value,
            valueGetter: (value) => formatDate(convertDate(value)),
        },
        {
            field: "prospectName",
            headerName: "Prospect Name",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center",
            width: 200,
        },
        {
            field: "prospectPhone",
            headerName: "Prospect Phone",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center",
            width: 150,
        },
        {
            field: "prospectEmail",
            headerName: "Prospect Email",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center",
            width: 200,
        },
        {
            field: "value",
            headerName: "Value",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center",
            width: 100,
        },
        {
            field: "remark",
            headerName: "Remark",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center",
            width: 250,
        },
        {
            field: "agentNames",
            headerName: "Agent Name (Percentage)",
            headerAlign: "center", // Center the header
            headerClassName: "header-bold",
            align: "center",
            width: 300,
            renderCell: (params: GridRenderCellParams) => {
                const agentNames = params.row.agentNames || "";
                const percentages = params.row.percentages || "";

                // Pair agent names with percentages and format them
                return agentNames
                    .map((name: string, index: number) => {
                        const percentage = percentages[index] || "0"; // Default to "0" if percentage is missing
                        return `${name} (${percentage}%)`;
                    })
                    .join(", ");
            },
            valueGetter: (value: any) => {
                if (!value) return "";
                const returnValue = value.join(", ");
                return returnValue;
            },
        },
    ]);

    const displayRows = useMemo(() => {
        if (!caseData) return [];
        let newCases = [...caseData];
        if (selectedAgent) {
            newCases = newCases.filter((caseItem) => caseItem?.agentIds.includes(selectedAgent));
        }
        return newCases;
        //    // Map customFields into each row if needed
        //    return newCases.map((caseItem) => ({
        //        ...caseItem,
        //        customFields: caseItem?.customFields || [], // Ensures customFields is always defined
        //    }));
    }, [selectedAgent, caseData]);

    const handleDeselect = () => {
        setRowSelectionModel([]); // Deselect all rows
    };

    return (
        <Box>
            <Box m={1}>
                <Typography>
                    <h1>Cases Report</h1>
                </Typography>
            </Box>
            <Box m={1}>
                <Button onClick={handleBackClick} variant="contained">
                    Back
                </Button>
            </Box>

            <Box display="flex" justifyContent="flex-start" alignItems="center" p={2} flexWrap={"wrap"}>
                <FormControl>
                    <Box display="flex" alignItems="center" justifyContent={["flex-start", "center"]}>
                        <Typography mr={1}>Select Company:</Typography>
                        <Select
                            value={selectedCompany}
                            onChange={handleCompanyChange}
                            displayEmpty
                            sx={{ width: 200 }}
                        >
                            <MenuItem value="" disabled>
                                Select a company
                            </MenuItem>
                            {companies.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </FormControl>
                <FormControl>
                    <Box display="flex" alignItems="center" justifyContent={["flex-start", "center"]} m={2}>
                        <Typography sx={{ marginRight: "8px" }}>Select Agent:</Typography>
                        <Select
                            value={selectedAgent}
                            onChange={handleAgentChange}
                            displayEmpty
                            sx={{ width: 200 }}
                        >
                            <MenuItem value="">All Agents</MenuItem>
                            {agents.map((agent) => (
                                <MenuItem key={agent.id} value={agent.id}>
                                    {agent.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </FormControl>

                <Box display="flex" alignItems="center" ml={4}>
                    <Typography mr={1}>Date Range:</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box>
                            <DatePicker
                                label="Date From"
                                value={dateFrom}
                                onChange={(newValue: Dayjs | null) => setDateFrom(newValue)}
                                // renderInput={(params: TextFieldProps) => <TextField {...params} />}
                            />
                        </Box>
                        <Box ml={4}>
                            <DatePicker
                                label="Date To"
                                value={dateTo}
                                onChange={(newValue: Dayjs | null) => setDateTo(newValue)}
                                // renderInput={(params: TextFieldProps) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box style={{ height: 600, width: "100%" }}>
                {/* <Button onClick={handleDeselect}>Deselect Row</Button> */}

                <DataGrid
                    rows={displayRows || []} // Ensure rows is an empty array if caseData is undefined
                    columns={columns}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={(newSelection: GridRowSelectionModel) => {
                        setRowSelectionModel([...newSelection]); // Spread to create a mutable array
                    }}
                    disableRowSelectionOnClick // Disable row selection
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20, 50]}
                    getRowId={(row) => row.id}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: {
                                fileName: "cases-report",
                                delimiter: ",",
                                utf8WithBom: true,
                            },
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default CasesReport;
