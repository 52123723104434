export default function generateSecret(clientId, secretCounter = 1) {
    if (!clientId) return null;

    // Load constants from environment variables
    const CHAR_OFFSET = parseInt(process.env.REACT_APP_CHAR_OFFSET, 10); // Default to 0
    const MULTIPLIER = parseInt(process.env.REACT_APP_MULTIPLIER, 10); // Default to 2
    const SUBSTRING_LENGTH = parseInt(process.env.REACT_APP_SUBSTRING_LENGTH, 10); // Default to 10
    const COUNTER_PADDING = parseInt(process.env.REACT_APP_COUNTER_PADDING, 10); // Default to 3

    const code = clientId
        .toLowerCase()
        .split("")
        .map((char) => (char.charCodeAt(0) - "a".charCodeAt(0) + CHAR_OFFSET) * MULTIPLIER)
        .join("");

    let generatedCode =
        parseFloat(code.substring(0, SUBSTRING_LENGTH)) * parseFloat(secretCounter + MULTIPLIER);
    generatedCode = generatedCode.toString();
    generatedCode = generatedCode.split("").reverse().join("");

    const counter = secretCounter.toString().padStart(COUNTER_PADDING, "0");

    const transformedCode = code.substring(0, SUBSTRING_LENGTH) + counter + generatedCode;

    return transformedCode;
}
