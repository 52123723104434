import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import convertDate from "./../../utils-functions/convertDate";

const getLeadsByPageId = async ({
    pageId,
    user,
    loadAll = false,
    type = "SOURCE_LEADS",
    year,
    startDate,
    endDate,
    folder,
    setLabelsList,
}) => {
    const collectionRef = collection(db, "leads");
    let q;

    // Get first day of year and last day of year
    if (year) {
        startDate = new Date(year, 0, 1);
        endDate = new Date(year, 11, 31, 23, 59, 59);
    }

    try {
        if (type === "SOURCE_LEADS") {
            // SOURCE LEADS
            if (folder) {
                let folderName = user.id + ":" + folder.id;
                console.log(folderName);
                q = query(
                    collectionRef,
                    where("folders", "array-contains", folderName),
                    orderBy("created", "desc")
                );
                //
            } else if (pageId === "all") {
                // load all regardless of page
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("admins", "array-contains", user.id),
                        orderBy("created", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("admins", "array-contains", user.id),
                        where("created", ">=", startDate),
                        where("created", "<=", endDate),
                        orderBy("created", "desc")
                    );
                }
            } else {
                // load by page
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("admins", "array-contains", user.id),
                        orderBy("created", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("admins", "array-contains", user.id),
                        where("created", ">=", startDate),
                        where("created", "<=", endDate),
                        orderBy("created", "desc")
                    );
                }
            }
            //
        } else {
            // ASSIGNED LEADS
            if (folder) {
                let folderName = user.id + ":" + folder.id;
                console.log(folderName);
                q = query(
                    collectionRef,
                    where("folders", "array-contains", folderName),
                    where("type", "==", "ASSIGNED_LEAD"),
                    orderBy("created", "desc")
                );
            } else if (pageId === "all") {
                // load all regardless of page
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("warriors", "array-contains", user.id),
                        orderBy("assignedDate", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("warriors", "array-contains", user.id),
                        where("assignedDate", ">=", startDate),
                        where("assignedDate", "<=", endDate),
                        orderBy("assignedDate", "desc")
                    );
                }
            } else {
                // load by page
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("warriors", "array-contains", user.id),
                        orderBy("assignedDate", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("warriors", "array-contains", user.id),
                        where("assignedDate", ">=", startDate),
                        where("assignedDate", "<=", endDate),
                        orderBy("assignedDate", "desc")
                    );
                }
            }
        }

        const snapshot = await getDocs(q);

        const labelsList = [];
        const uniqueLabels = [];

        if (!snapshot.empty) {
            let leads = snapshot.docs.map((doc) => {
                let data = {};
                try {
                    if (doc.data().field_data) {
                        doc.data().field_data.forEach((item) => {
                            if (item.name?.includes("name") || item.name?.includes("全名"))
                                data = { ...data, name: item.values[0] };
                            else if (item.name?.includes("email") || item.name?.includes("邮箱"))
                                data = { ...data, email: item.values[0] };
                            else if (item.name?.includes("phone") || item.name?.includes("手机号"))
                                data = { ...data, phone: item.values[0] };
                            else {
                                data = { ...data, [item.name]: item.values[0] };
                            }
                        });
                    }
                    const found = doc.data().assignments?.find((item) => item.assign?.email === user.id);
                    let assignBy = found ? found.assignBy : "";
                    assignBy = assignBy.split("@")[0];

                    const assignedDate = found ? convertDate(found.date) : null;

                    let date = convertDate(doc.data().created);

                    if (doc.data().type === "ASSIGNED_LEAD") {
                        date.setSeconds(date.getSeconds() - 1);
                    }

                    if (doc.data().labels) {
                        doc.data().labels.forEach((label) => {
                            if (!uniqueLabels.includes(label.label)) {
                                uniqueLabels.push(label.label);

                                labelsList.push({ name: label.label, count: 1 });
                            } else {
                                const index = labelsList.findIndex((item) => item.name === label.label);
                                labelsList[index].count += 1;
                            }
                        });
                    }

                    return {
                        ...doc.data(),
                        ...data,
                        created: date,
                        assignBy,
                        id: doc.id,
                        ...(assignedDate && { assignedDate }), // Only add assignedDate if it's not null
                    };
                } catch (err) {
                    console.log(err);
                }
            });

            if (type === "SOURCE_LEADS") {
                leads = sortLeads(leads);
                if (setLabelsList) {
                    setLabelsList(labelsList);
                }
            } else {
                leads.sort((a, b) => b.assignedDate - a.assignedDate);
                if (setLabelsList) {
                    setLabelsList(labelsList);
                }
            }

            return leads;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err.message);
        return [];
    }
};

export default getLeadsByPageId;

// Function to sort leads
function sortLeads(leads) {
    // Group leads by source lead
    const groupedLeads = leads.reduce((acc, lead) => {
        const [sourceLeadId] = lead.id.split(":");
        if (!acc[sourceLeadId]) {
            acc[sourceLeadId] = [];
        }
        acc[sourceLeadId].push(lead);
        return acc;
    }, {});

    // Sort each group by created date and flatten the groups
    return Object.values(groupedLeads)
        .map((group) => group.sort((a, b) => new Date(b.created) - new Date(a.created)))
        .sort((a, b) => new Date(b[0].created) - new Date(a[0].created))
        .flat();
}
