import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Container, Grid, Icon, IconButton, useTheme } from "@mui/material";
import { orange } from "@mui/material/colors";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import ColorPicker from "../components/ColorPicker";
import { LeadsFlexBox } from "../components/LeadsFlexBox";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { useInput } from "../context-utils/InputContext";
import useList from "../context-utils/ListContext";
import { AuthContext } from "../context/AuthContext";
import { useGetFolders, useGetLabels } from "../context/StoreContext";
import { db } from "../firebase/firebase-utils";
import addFolder from "../functions/addFolder";
import addLabel from "../functions/addLabel";
import addLabelToLead from "../functions/addLabelToLead";
import addLeadsIntoFolder from "../functions/addLeadsIntoFolder";
import addUserProps from "../functions/addUserProps";
import updateField from "../functions/database-functions/upadateField";
import { deleteLeads } from "../functions/deleteLeads";
import loadUserProps from "../functions/loadUserProps";
import removeLabelFromLead from "../functions/removeLabelFromLead";
import { tokens } from "../theme";
import { GridContainer, GridFlexBox, Name, Title } from "../themes/themes";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";

const ITEMS = [
    { type: "folders", label: "Put into Folder" },
    { type: "labels", label: "Message reaction" },
];

export default function LeadCardPage({ lead, handleClose, leadsMode, handleUpdateLead = () => {} }) {
    const [loading, setLoading] = useState(false);
    const [maxNumber, setMaxNumber] = useState(250);
    const [userProps, setUserProps] = useState({ language: "", items: ITEMS });
    const [openColor, setOpenColor] = useState();
    const [leadId, setLeadId] = useState(lead?.id || "");
    const [currentLead, setCurrentLead] = useState({ ...lead });

    useEffect(() => {
        if (!leadId) return;

        const docRef = doc(db, "leads", leadId);
        const unsubscribe = onSnapshot(docRef, async (docSnapshot) => {
            const currentLead = mapDocSnapshot(docSnapshot);

            const updates = [];

            if (!currentLead?.name) {
                const name = lead.name || "";
                currentLead.name = name;
                updates.push(updateDoc(docRef, { name }));
            }

            if (!currentLead?.email) {
                const email = lead.email || "";
                currentLead.email = email;
                updates.push(updateDoc(docRef, { email }));
            }

            if (!currentLead?.phone) {
                const phone = lead.phone || "";
                currentLead.phone = phone;
                updates.push(updateDoc(docRef, { phone }));
            }

            // Run all updateDoc calls concurrently
            if (updates.length > 0) {
                await Promise.all(updates);
            }

            setCurrentLead(currentLead);
            handleUpdateLead(currentLead);
        });

        return unsubscribe;
    }, [leadId]);

    useEffect(() => {
        if (!lead) return;

        setLeadId(lead.id);
    }, [lead]);

    const [language] = useMemo(() => {
        return [userProps?.language];
    }, [userProps]);

    const { user } = useContext(AuthContext);

    const getFolders = useGetFolders();
    const getLabels = useGetLabels();
    const list = useList();
    const input = useInput();

    const handleClick = async (type, i) => {
        const folders = await getFolders(user);
        const labels = await getLabels(user, "messageReaction");
        switch (type) {
            case "folders":
                await handleAddIntoFolder({ list, folders, input, user, lead, userProps, i, setUserProps });
                break;
            case "labels":
                await handleAddLabels(list, labels, input, user, userProps, i, setUserProps, lead);
        }
    };

    useEffect(() => {
        const unsubscribe = loadUserProps(user, lead, setUserProps);

        return () => {
            setUserProps({ language: "", items: ITEMS });
            unsubscribe?.();
        };
    }, []);

    const handleClickLanguage = async (language) => {
        console.log(language);
        setUserProps((prevState) => ({ ...prevState, language }));
        await addUserProps(user, lead, { language });
    };

    const handleClear = async () => {
        await addUserProps(user, lead, { language: "", items: ITEMS });
        setUserProps({ language: "", items: ITEMS });
    };

    const confirm = useConfirmation();

    const handleDelete = async () => {
        const response = await confirm("Delete lead", "Press OK to delete");
        if (response) {
            await deleteLeads([lead.id]);
            handleUpdateLead(lead, true);
            handleClose();
        }
    };

    const handleClickColor = (lead) => {
        setOpenColor(lead);
    };

    const handlePickColor = async (value) => {
        console.log("handlePickColor value: ", value);
        await updateField({ docId: openColor.id, collectionId: "leads", field: "color", value });
        setOpenColor();
    };

    return (
        <Container maxWidth="md" disableGutters>
            <GridContainer>
                <GridFlexBox>
                    <Title>Lead Card</Title>
                </GridFlexBox>
                <LeadsFlexBox
                    leads={currentLead ? [currentLead] : null}
                    title={"Lead"}
                    maxNumber={maxNumber}
                    setMaxNumber={setMaxNumber}
                    leadsMode={leadsMode}
                    setLoading={setLoading}
                    loading={loading}
                    triggerSlide={() => {}}
                    displayHeader={false}
                    enableSelect={false}
                    limitActions={1000}
                    displayOpenCard={false}
                />
                {userProps?.items?.map((item, i) => (
                    <Item
                        id={item.id}
                        key={item.label}
                        onClick={() => handleClick(item.type, i)}
                        label={item.label}
                        value={item.value || ""}
                    />
                ))}
                <SelectLanguage handleClick={handleClickLanguage} language={language} />
                <GridContainer m1>
                    <GridFlexBox xs={12} fs>
                        <Name fs14>Color</Name>
                    </GridFlexBox>
                    <GridFlexBox xs={12} fs>
                        <Button fullWidth variant="outlined" onClick={() => handleClickColor(lead)}>
                            +
                        </Button>
                    </GridFlexBox>
                </GridContainer>
                <ColorPicker open={openColor} setOpen={setOpenColor} onClose={handlePickColor}></ColorPicker>
                <GridFlexBox fe>
                    <IconButton onClick={handleClear}>
                        <Name color="red">Clear all properties</Name>
                        <Icon color="warning">clear</Icon>
                    </IconButton>
                </GridFlexBox>
                {lead.admins.includes(user.id) && (
                    <GridFlexBox fe>
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={handleDelete}
                            sx={{ margin: "8px" }}
                        >
                            Delete Lead
                        </Button>
                    </GridFlexBox>
                )}
            </GridContainer>
        </Container>
    );
}

const Item = ({ value, label = "label", onClick = () => {} }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <GridContainer m1>
            <GridFlexBox fs>
                <Name fs14>{label}</Name>
            </GridFlexBox>
            <Grid item xs={12}>
                <Box
                    border="1px solid"
                    borderRadius={"10px"}
                    borderColor={colors.grey[800]}
                    width={"100%"}
                    height={"50px"}
                    style={{ cursor: "pointer" }}
                    p={1}
                    display="flex"
                    alignItems="center"
                    onClick={onClick}
                >
                    <GridFlexBox xs={11} fs>
                        {value}
                    </GridFlexBox>
                    <GridFlexBox xs={1} fe>
                        <ArrowDropDownIcon />
                    </GridFlexBox>
                </Box>
            </Grid>
        </GridContainer>
    );
};

const SelectLanguage = ({ handleClick, language }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = { border: "1px solid", borderColor: colors.grey[800], padding: "16px", cursor: "pointer" };

    const checkLanguage = (lang) => {
        if (language === lang) {
            return orange[500];
        }
    };
    return (
        <GridContainer m1>
            <GridFlexBox xs={12} fs>
                <Name fs14>Language</Name>
            </GridFlexBox>
            <GridFlexBox
                xs={4}
                style={style}
                onClick={() => handleClick("English")}
                bgcolor={checkLanguage("English")}
            >
                English
            </GridFlexBox>
            <GridFlexBox
                xs={4}
                style={style}
                onClick={() => handleClick("Mandarin")}
                bgcolor={checkLanguage("Mandarin")}
            >
                Mandarin
            </GridFlexBox>
            <GridFlexBox
                xs={4}
                style={style}
                onClick={() => handleClick("Malay")}
                bgcolor={checkLanguage("Malay")}
            >
                Malay
            </GridFlexBox>
        </GridContainer>
    );
};

async function handleAddLabels(list, labels, input, user, userProps, i, setUserProps, lead) {
    {
        const response = await list(labels, "Add label", "label", "label_outlined", true, true);
        if (response === "addAccount") {
            const labelName = await input("Label Name", "Enter your label name", "label name", "");
            if (labelName) {
                const newLabel = {
                    color: orange[500],
                    fontColor: "white",
                    label: labelName,
                    name: labelName,
                    userEmail: user.id,
                    type: "messageReaction",
                };
                const id = await addLabel(user, newLabel);
                const newUserProps = { ...userProps };
                const oldLabelName = newUserProps.items[i]["value"];
                newUserProps.items[i]["value"] = labelName;
                newUserProps.items[i]["labelId"] = id;
                setUserProps(newUserProps);
                const newLead = await removeLabelFromLead(lead, user, oldLabelName);
                await addLabelToLead(newLead, user, newLabel);
                await addUserProps(user, lead, { items: newUserProps.items });
            }
        } else if (response) {
            const newUserProps = { ...userProps };
            const oldLabelName = newUserProps.items[i]["value"];
            newUserProps.items[i]["value"] = response.label;
            newUserProps.items[i]["labelId"] = response.id;
            setUserProps(newUserProps);
            const newLead = await removeLabelFromLead(lead, user, oldLabelName);
            await addLabelToLead(newLead, user, response);
            await addUserProps(user, lead, { items: newUserProps.items });
        }
    }
}

async function handleAddIntoFolder({ list, folders, input, user, lead, userProps, i, setUserProps }) {
    const response = await list(folders, "Folders", "folderName", "folder", true, true);
    if (response === "addAccount") {
        const folderName = await input("Folder Name", "Enter your folder name", "folder name", "");
        if (folderName) {
            const id = await addFolder(folderName, user);
            const folder = { id, folderName };
            console.log("add folder", folderName);
            await addLeadsIntoFolder(folder, user, [lead]);
            const newState = [...userProps.items];
            newState[i].value = folderName;
            newState[i].folderId = `${user.id}:${id}`;
            setUserProps((prev) => ({ ...prev, items: newState }));
            await addUserProps(user, lead, { items: newState });
        }
    } else if (response) {
        await addLeadsIntoFolder(response, user, [lead]);
        const { id, folderName } = response;
        const newState = [...userProps.items];
        newState[i].value = folderName;
        newState[i].folderId = `${user.id}:${id}`;
        setUserProps((prev) => ({ ...prev, items: newState }));
        await addUserProps(user, lead, { items: newState });
    }
}
