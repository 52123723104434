import MoneyIcon from "@mui/icons-material/Money";
import { green, grey } from "@mui/material/colors";
import { DataGrid, GridActionsCellItem, GridToolbar, GridColDef as MuiGridColDef } from "@mui/x-data-grid";
import { addDoc, collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { Name } from "../../themes/themes";
import convertToNumber from "../../utils-functions/convertToNumber";
import formatNumber from "../../utils-functions/formatNumber";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import getTransfers from "../AffiliatePage/getTransfers";
import { UserType } from "./types";

export default function CommissionTable({ commissionTable }: any) {
    const [rows, setRows] = useState<any>([]);
    const [paymentLoadings, setPaymentLoadings] = useState<any>([]);
    const [paymentStatus, setPaymentStatus] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [stripeCreatePaymentIds, setStripeCreatePaymentId] = useState<any>([]);

    const { user } = useContext(AuthContext) as { user: UserType };

    useEffect(() => {
        const array: any = [];

        const loadData = async () => {
            setLoading(true);
            await Promise.all(
                Object.keys(commissionTable).map(async (key) => {
                    const docRef = doc(db, "users", key);
                    const snapshot = await getDoc(docRef);
                    const displayName = snapshot?.data()?.displayName || "";
                    const connectedStripeId = snapshot?.data()?.connectedStripeId || "";
                    const phone = snapshot?.data()?.phone || "";

                    const totalCommission = commissionTable[key];

                    const totalCommissionPaid = await getTransfers({ connectedStripeId });
                    const balance = totalCommission - totalCommissionPaid;

                    const obj = {
                        id: key,
                        totalCommission,
                        totalCommissionPaid,
                        email: key,
                        displayName,
                        connectedStripeId,
                        phone,
                        balance,
                    };

                    array.push(obj);
                })
            );
            console.log(array);
            setRows(array);
            setLoading(false);
        };

        loadData();
    }, [commissionTable]);

    useEffect(() => {
        const newRows = [...rows];
        paymentStatus.forEach((item: any) => {
            const row = newRows.find((row: any) => row.id === item.rowId);
            if (row) {
                row.paymentStatus = item.status;
            }
        });
        setRows(newRows);
    }, [paymentStatus]);

    useEffect(() => {
        // Array to store all unsubscribe functions
        const unsubscribes: (() => void)[] = [];

        // Create listeners for each payment ID
        stripeCreatePaymentIds.forEach((id: string) => {
            const docRef = doc(collection(db, "stripeCreatePayments"), id);

            // Create listener for this document
            const unsubscribe = onSnapshot(
                docRef,
                (docSnapshot) => {
                    const data = mapDocSnapshot(docSnapshot);
                    const status = data?.status;

                    setPaymentStatus((prevStatus: any) =>
                        prevStatus.map((item: any) => (item.id === id ? { ...item, status } : item))
                    );
                    console.log(data);
                },
                (error) => {
                    console.error(`Error listening to document ${id}:`, error);
                }
            );

            // Store the unsubscribe function
            unsubscribes.push(unsubscribe);
        });

        // Cleanup function to remove all listeners when component unmounts
        // or when stripeCreatePaymentIds changes
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        };
    }, [stripeCreatePaymentIds]);

    const input = useInput();

    const sendMoney = async (row: any) => {
        console.log(row);

        const response = await input("Amount", "Amount to send", "Amount", convertToNumber(row.balance));

        if (response) {
            const collectionRef = collection(db, "stripeCreatePayments");
            if (!row.connectedStripeId) {
                toast.error("No connected stripe account");
                return;
            }

            const ref = await addDoc(collectionRef, {
                amount: parseFloat(response),
                connectedStripeId: row.connectedStripeId,
                date: new Date(),
                currency: "myr",
                displayName: row.displayName,
                email: row.id,
                status: "processing",
            });

            setStripeCreatePaymentId((prev: any) => {
                const array = prev ? [...prev, ref.id] : [ref.id];
                // remove duplicates
                return Array.from(new Set(array));
            });

            const newPaymentLoadings = [...paymentLoadings, row.id];
            setPaymentLoadings(newPaymentLoadings);

            const newPaymentStatus = [...paymentStatus, { id: ref.id, rowId: row.id, status: "processing" }];
            setPaymentStatus(newPaymentStatus);
        }
    };

    type GridColDef = MuiGridColDef & { role?: string };

    const columns = useMemo<GridColDef[]>(
        () => [
            { field: "id", headerName: "id", width: 200 },
            { field: "displayName", headerName: "Name", width: 150 },
            { field: "phone", headerName: "Phone", width: 150 },
            {
                field: "totalCommission",
                headerName: "Total Commission",
                width: 150,
                align: "right", // Right align the cell content
                headerAlign: "right", // Right align the header content
                renderCell: (params) => formatNumber(params.value, 2),
            },
            {
                field: "totalCommissionPaid",
                headerName: "Total Commission Paid",
                width: 150,
                align: "right", // Right align the cell content
                headerAlign: "right", // Right align the header content
                renderCell: (params) => formatNumber(params.value, 2),
            },
            {
                field: "balance",
                headerName: "Balance",
                align: "right", // Right align the cell content
                headerAlign: "right", // Right align the header content
                renderCell: (params) => (
                    <Name color={params.value > 0.1 ? green[500] : grey[500]}>
                        {formatNumber(params.value, 2)}
                    </Name>
                ),
            },
            {
                field: "connectedStripeId",
                headerName: "Stripe Id",
                width: 80,
            },
            {
                field: "sendMoney",
                role: "Super Admin",
                type: "actions",
                width: 50,
                getActions: (params) => [
                    <GridActionsCellItem
                        disabled={paymentLoadings.includes(params.id) || user?.id !== "daveckw@gmail.com"}
                        key={params.id}
                        icon={<MoneyIcon />}
                        label="Delete"
                        onClick={() => sendMoney(params.row)}
                    />,
                ],
            },
            {
                field: "paymentStatus",
                width: 100,
                role: "Super Admin",
                headerName: "Status",
                renderCell: (params) => params.value || "",
            },
        ],
        [commissionTable]
    );

    const columnsToDisplay = useMemo(() => {
        if (user?.role === "Super Admin") {
            return columns;
        } else {
            return columns.filter((column) => column.role !== "Super Admin");
        }
    }, [user]);

    return (
        <>
            <DataGrid
                columns={columnsToDisplay}
                rows={rows}
                slots={{ toolbar: () => <GridToolbar /> }}
                slotProps={{
                    loadingOverlay: {
                        variant: "skeleton",
                        noRowsVariant: "skeleton",
                    },
                }}
                loading={loading}
            />
        </>
    );
}
