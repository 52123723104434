import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Card, Grid, LinearProgress, Switch, Typography } from "@mui/material";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

interface Plan {
    name: string;
    price: string;
    description: string;
    features: string[];
    isPopular?: boolean;
    tag?: string;
    pricePerYear: string;
    priceId: string; // Add priceId for Stripe integration
    priceIdYearly: string;
}

const plans: Plan[] = [
    {
        name: "Starter",
        price: "RM238",
        pricePerYear: "RM191",
        description: "For individual marketer just getting started with AI Automation",
        features: [
            "What's included",
            "DJC Leads Management",
            "DJC Whatsapp Broadcast",
            "DJC Whatsapp AI Flows",
            "DJC Whatsapp Sequences",
            "DJC Email Marketing",
            "DJC Landing Page Builder",
            "DJC Training Courses",
            "DJC Tutorials and Courses",
        ],
        priceId: "price_1QUXsmLWoLTwDp2IJJs12JkL",
        priceIdYearly: "price_1QUXu6LWoLTwDp2IrTWf7ZXZ",
    },
    {
        name: "Premium",
        price: "RM358",
        pricePerYear: "RM283",
        description: "For small teams and marketers looking to scale AI Automation",
        isPopular: true,
        features: [
            "Everything in Starter, plus:",
            "Additional 2 team members with full AI access (total 3)",
            "Team Inboxes",
            "AI Images Generation 20 credits/month",
            "Shared Inboxes and Chat Rooms",
            "Rapid System Support",
        ],
        priceId: "price_1QUXulLWoLTwDp2IiNauJ3dZ",
        priceIdYearly: "price_1QUXvQLWoLTwDp2IlhfFrBPv",
    },
    {
        name: "Corporate",
        price: "RM688",
        pricePerYear: "RM550",
        description: "For enterprises looking to dominate their industry with advanced AI solutions",
        features: [
            "Everything in Premium, plus:",
            "Additional 5 team members with full AI access (total 6)",
            "Customised Training Programs tailored to your team",
            "Premium Customer Support with a dedicated account manager",
            "End-to-End Automation Design for your workflows",
            "Exclusive AI Innovation Updates",
        ],
        tag: "Best Value",
        priceId: "price_1QUXFWLWoLTwDp2I2pZhYwef",
        priceIdYearly: "price_1QUXGYLWoLTwDp2IIZMDSKEZ",
    },
];

const StripeCheckoutPage: React.FC = () => {
    const { user } = useContext(AuthContext);
    const { params } = useParams();

    const [isYearly, setIsYearly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stripeId, setStripeId] = useState("");
    const [checkoutId, setCheckoutId] = useState("");
    const [docId, setDocId] = React.useState<string>();
    const [url, setUrl] = useState("");
    const [freeTrial, setFreeTrial] = useState(0);
    const [startProcess, setStartProcess] = useState(false);
    const [priceId, setPriceId] = useState("");
    const [product, setProduct] = React.useState("");

    useEffect(() => {
        if (params?.includes("free-trial")) {
            setFreeTrial(7);
        }

        if (params?.includes("free-trial-eliteone")) {
            setFreeTrial(30);
        }
    }, []);

    useEffect(() => {
        console.log(docId);
        console.log("Version 2024-03-06");
        if (docId) {
            console.log("docId: ", docId);
            const docRef = doc(db, "customers", user.uid, "checkout_sessions", docId);
            return onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data() as any;
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                    // setLoading(false);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    // console.log("url: ", url);
                    // setUrl(url);
                    // setLoading(false);
                }
            });
        }
    }, [docId]);

    useEffect(() => {
        if (checkoutId) {
            console.log("Useeffect checkoutId: ", checkoutId);
            const docRef = doc(db, "stripeCheckouts", checkoutId);
            return onSnapshot(docRef, (snap) => {
                const checkout = mapDocSnapshot(snap);
                if (checkout?.url) {
                    console.log("checkout.url: ", checkout.url);
                    setUrl(checkout.url);
                    setLoading(false);
                }
                console.log("checkout: ", checkout);
            });
        }
    }, [checkoutId]);

    useEffect(() => {
        console.log("startProcess: ", startProcess);
        console.log("stripeId: ", stripeId);
        if (!startProcess) return;
        if (!stripeId) return;
        console.log("startProcess2: ", startProcess);

        checkout(priceId);
    }, [stripeId, startProcess]);

    useEffect(() => {
        if (url) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [url]);

    useEffect(() => {
        if (user.uid) {
            const docRef = doc(db, "customers", user.uid);
            return onSnapshot(docRef, (snap) => {
                const customer = mapDocSnapshot(snap);
                if (customer?.stripeId) {
                    setStripeId(customer.stripeId);
                    console.log("stripeId: ", customer.stripeId);
                }
            });
        }
    }, [user]);

    const loadStripe = async (plan: Plan) => {
        //
        console.log(plan);
        const selectedPriceId = isYearly ? plan.priceIdYearly : plan.priceId;
        console.log("loadStripe", selectedPriceId);

        setPriceId(selectedPriceId);
        setProduct(plan.name);

        // This is used only to create customer in Stripe through Firebase Extension, not the actual checkout
        if (!stripeId) {
            const collectionRef = collection(db, "customers", user.uid, "checkout_sessions");
            setLoading(true);
            const docRef = await addDoc(collectionRef, {
                price: selectedPriceId,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
            });

            console.log(user.uid);

            if (docRef.id) {
                console.log(docRef.id);
                setDocId(docRef.id);
                setStartProcess(true);
            } else {
                setLoading(false);
                toast.error("Error creating checkout session");
            }
        } else {
            checkout(selectedPriceId);
        }
    };

    const checkout = async (priceId: string) => {
        console.log("checkout");
        const collectionRef = collection(db, "stripeCheckouts");
        const trialDays = freeTrial;

        // Change this to allow promotion codes
        const allow_promotion_codes = true;

        const successURL = "https://djcsystem.com";
        const cancelURL = "https://djcsystem.com";
        const customerId = stripeId;
        setLoading(true);

        const response = await addDoc(collectionRef, {
            priceId,
            trialDays,
            successURL,
            cancelURL,
            customerId,
            allow_promotion_codes,
        });
        const checkoutId = response.id;
        console.log("checkoutId: ", checkoutId);
        setCheckoutId(checkoutId);
    };

    const CheckOut = () => {
        return (
            <>
                <Box width={"100%"}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <Button
                            variant="contained"
                            size="large"
                            sx={{ width: "100%" }}
                            disabled={loading}
                            color="warning"
                        >
                            {freeTrial ? "Start My Free Trial" : "Start my Subscription"}
                        </Button>
                    </a>
                </Box>
                <Box mt={"8px"}>
                    {freeTrial ? (
                        <Typography variant="body2">
                            {freeTrial} days free from today, then just {product} (plus applicable taxes).
                            Cancel through your dashboard or by e-mail (admin@djc.ai). I also agree to the{" "}
                            <a
                                href="https://www.djc.ai/termsandservices"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                                href="https://www.djc.ai/privacypolicy"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Privacy Policy
                            </a>
                        </Typography>
                    ) : (
                        // No free trial
                        <Typography variant="body2">
                            {product} (plus applicable taxes). Cancel through your dashboard or by e-mail
                            (admin@djc.ai). I also agree to the{" "}
                            <a
                                href="https://www.djc.ai/termsandservices"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                                href="https://www.djc.ai/privacypolicy"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline", color: "inherit" }}
                            >
                                Privacy Policy
                            </a>
                        </Typography>
                    )}
                </Box>
            </>
        );
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsYearly(event.target.checked);
    };

    return (
        <Box sx={{ padding: 4, maxWidth: "1000px", margin: "auto" }}>
            <Typography variant="h4" align="center" gutterBottom>
                Pricing
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
                From individual marketer to the biggest enterprises, we have a plan for you.
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 4 }}>
                <Typography variant="body1" sx={{ mr: 1 }}>
                    MONTHLY
                </Typography>
                <Switch checked={isYearly} onChange={handleSwitchChange} color="primary" />
                <Typography variant="body1" sx={{ ml: 1 }}>
                    YEARLY
                </Typography>

                <Typography
                    variant="body2"
                    sx={{
                        ml: 2,
                        backgroundColor: "#D5F5E3",
                        color: "#27AE60",
                        padding: "2px 8px",
                        borderRadius: "4px",
                    }}
                >
                    20% OFF
                </Typography>
            </Box>

            <Grid container spacing={4}>
                {plans.map((plan) => (
                    <Grid item xs={12} sm={6} md={4} key={plan.name}>
                        <Card
                            sx={{
                                position: "relative", // Enable positioning for the tags
                                padding: 3,
                                border: plan.isPopular ? "2px solid #000" : "1px solid #ddd",
                                boxShadow: plan.isPopular ? 4 : 1,
                                height: "500px", // Fixed height for all pricing boxes
                                display: "flex", // Ensure content is properly aligned
                                flexDirection: "column",
                                justifyContent: "space-between", // Distribute content vertically
                            }}
                        >
                            {/* Tag for "Popular" and "Best Value" */}
                            {plan.isPopular && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        backgroundColor: "#FF5722",
                                        color: "#fff",
                                        padding: "4px 10px",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                                    }}
                                >
                                    Most Popular
                                </Box>
                            )}
                            {plan.tag === "Best Value" && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        backgroundColor: "#4CAF50",
                                        color: "#fff",
                                        padding: "4px 10px",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                                    }}
                                >
                                    Best Value
                                </Box>
                            )}

                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    {plan.name}
                                </Typography>
                                <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
                                    <Typography variant="h4" gutterBottom>
                                        {isYearly ? plan.pricePerYear : plan.price}
                                    </Typography>
                                    {isYearly && (
                                        <Typography variant="subtitle1" gutterBottom>
                                            x 12
                                        </Typography>
                                    )}
                                </Box>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    {plan.description}
                                </Typography>
                                <Box sx={{ mt: 2 }} display="flex" flexDirection={"column"} gap={1}>
                                    {plan.features.map((feature, index) => (
                                        <Box key={index}>
                                            {index === 0 ? (
                                                <Box display="flex" alignItems={"center"} gap={1}>
                                                    <Typography variant="body2">{feature}</Typography>
                                                </Box>
                                            ) : (
                                                <Box display="flex" alignItems={"normal"} gap={1}>
                                                    <CheckCircleOutlineIcon
                                                        color="primary"
                                                        fontSize="small"
                                                        sx={{ marginTop: "2px" }}
                                                    />
                                                    <Typography variant="body1">{feature}</Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Button
                                variant="contained"
                                color={plan.isPopular ? "primary" : "secondary"}
                                fullWidth
                                sx={{ mt: 3 }}
                                onClick={() => loadStripe(plan)}
                                disabled={
                                    (user.addOn === "whatsappCloud" || loading) && user.role !== "Super Admin"
                                }
                            >
                                Get Started
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box width={"100%"} my={2}>
                {loading && <LinearProgress />}
                {url && <CheckOut />}
            </Box>
        </Box>
    );
};

export default StripeCheckoutPage;
