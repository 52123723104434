import {
    Autocomplete,
    Box,
    Chip,
    Divider,
    Icon,
    IconButton,
    Paper,
    TextField,
    Typography,
    Button,
    Dialog,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { collection, deleteDoc, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Handle, Position } from "reactflow";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { Name } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import FlowContext from "./FlowContext";
import ManageLabels from "../LeadsTablePageV3/ManageLabels";

export default function LabelNode({ data, isConnectable }) {
    const { user } = useContext(AuthContext);
    const [block, setBlock] = useState();
    const { setUpdateTrigger } = useContext(FlowContext);
    const [flows, setFlows] = useState([]);
    const [currentFlow, setCurrentFlow] = useState(data?.aiTriggerFlowId || "");
    const [searchFlow, setSearchFlow] = useState("");
    const [filteredFlows, setFilteredFlows] = useState([]);
    const [selectedFlow, setSelectedFlow] = useState("");
    const [openLabels, setOpenLabels] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);

    useEffect(() => {
        console.log("data:", data);
        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);

        const unsubscribe = onSnapshot(docRef, (snap) => {
            const block = mapDocSnapshot(snap);
            setBlock(block);
        });

        return unsubscribe;
    }, [data]);

    useEffect(() => {
        if (block) {
            const newStatus = selectedFlow?.name ? "active" : "inactive";
            if (block.status !== newStatus) {
                const updatedBlockObj = { ...block, status: newStatus };
                setBlock(updatedBlockObj); // Update local state

                const docRef = doc(db, "users", user.id, "whatsappFlows", block.flowId, "blocks", block.id);

                updateDoc(docRef, { status: newStatus }).then(() => {
                    setUpdateTrigger((prev) => prev + 1); // Trigger update in parent component
                });
            }
        }
    }, [selectedFlow]);

    const handleClickStatus = () => {
        const newStatus = block.status === "active" ? "inactive" : "active";

        const updatedBlockObj = { ...block, status: newStatus };
        setBlock(updatedBlockObj); // Update local state

        const docRef = doc(db, "users", user.id, "whatsappFlows", block.flowId, "blocks", block.id);

        updateDoc(docRef, { status: newStatus }).then(() => {
            setUpdateTrigger((prev) => prev + 1); // Trigger update in parent component
        });
    };

    //rerender status to bring rerender to parent component.
    const triggerRerender = () => {
        setUpdateTrigger((prev) => prev + 1); // Increment trigger to cause re-render
    };

    const confirmation = useConfirmation();

    const handleDelete = async () => {
        if (!block) return;

        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);

        try {
            const response = await confirmation("Delete block?", "Press OK to delete this block");
            if (!response) return;

            // delete all block relation that targetNodeId === blockId
            const blockRelationRef = collection(
                db,
                "users",
                createdBy,
                "whatsappFlows",
                flowId,
                "blocksRelation"
            );
            const q = query(blockRelationRef, where("targetNodeId", "==", blockId));

            // Execute the query to get the documents
            const querySnapshot = await getDocs(q);
            console.log("Number of documents found:", querySnapshot.size);

            // Iterate over each document and delete it
            for (const doc of querySnapshot.docs) {
                console.log("Document ID:", doc.id);
                // Create a reference to each document to be deleted
                const docRef = doc.ref;
                // Delete the document
                await deleteDoc(docRef);
                console.log(`Document with ID ${doc.id} successfully deleted!`);
            }

            // Get all blocks in the same flowId
            const blocksRef = collection(db, "users", createdBy, "whatsappFlows", flowId, "blocks");
            const allBlocksSnapshot = await getDocs(blocksRef);

            // Iterate through each block
            for (const blockDoc of allBlocksSnapshot.docs) {
                const blockData = blockDoc.data();
                if (Array.isArray(blockData.targetNodeId) && blockData.targetNodeId.includes(blockId)) {
                    // Remove blockId from the targetNodeId array
                    const updatedTargetNodeId = blockData.targetNodeId.filter((id) => id !== blockId);
                    // Update the block with the new targetNodeId array
                    await updateDoc(blockDoc.ref, { targetNodeId: updatedTargetNodeId });
                    console.log(`Updated block ${blockDoc.id} to remove targetNodeId ${blockId}`);
                }
            }

            // Delete the main block
            await deleteDoc(docRef);
            triggerRerender();
            toast.success("Label Node deleted successfully.");
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete Whatsapp Node.");
        }
    };

    const handleOpenLabels = () => {
        setOpenLabels(true);
    };

    const handleCloseLabels = (updatedLead) => {
        // Set labels regardless of whether updatedLead.labels exists or is empty
        console.log(updatedLead);
        const newLabels = (updatedLead?.labels || []).map((label) => ({
            // id: label.id,
            name: label.name,
            userEmail: label.userEmail,
            label: label.label,
            color: label.color,
            fontColor: label.fontColor,
        }));
        setSelectedLabels(newLabels);

        // Update the block with new labels (even if empty)
        if (block) {
            const docRef = doc(db, "users", user.id, "whatsappFlows", block.flowId, "blocks", block.id);
            updateDoc(docRef, { labels: newLabels })
                .then(() => {
                    setUpdateTrigger((prev) => prev + 1);
                })
                .catch((error) => {
                    console.error("Error updating labels: ", error);
                    toast.error("Failed to update labels");
                });
        }
        setOpenLabels(false);
    };

    // Load existing labels when block data changes
    useEffect(() => {
        if (block?.labels) {
            setSelectedLabels(block.labels);
        }
    }, [block]);

    return (
        <>
            {/* <style>
                {`
        @keyframes blinkGlow {
            0%, 100% {
                box-shadow: 0 0 40px #f7c652; // Full intensity
            }
            50% {
                box-shadow: 0 0 20px #f7c652; // Reduced intensity
            }
        }
        .glowing {
            animation: blinkGlow 1s ease-in-out infinite;
            z-index: 999; // Added z-index
        }
    `}
            </style> */}
            <Paper sx={{ width: "300px" }}>
                {block?.blockType == "labelNode" && (
                    <Handle
                        type="target"
                        position={Position.Left}
                        isConnectable={isConnectable}
                        style={{
                            background: "#525252",
                            width: "15px",
                            height: "15px",
                            borderRadius: "5px",
                            opacity: 0.5,
                        }}
                    />
                )}

                <Box p={1} display="flex" flexDirection={"column"} gap={1} className="nowheel">
                    <Box display="flex" alignItems={"center"} gap={1} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                            <Typography>Label Node</Typography>
                            <Chip
                                size="small"
                                label={block?.blockType}
                                // color={block?.blockType === "whatsappFlow" ? "primary" : "error"}
                                onClick={user?.role === "Super Admin" ? () => console.log(block) : undefined}
                            />
                        </Box>
                        <IconButton size="small" onClick={() => handleClickStatus()}>
                            <Name color={block?.status === "active" ? green[500] : red[500]}>
                                {block?.status || "inactive"}
                            </Name>
                        </IconButton>
                        <IconButton size="small" onClick={handleDelete}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Box>

                    <>
                        <Box display="flex" flexDirection="column" gap={1}>
                            <Button variant="contained" onClick={handleOpenLabels} fullWidth>
                                Manage Labels
                            </Button>
                            {selectedLabels.length > 0 && <Divider>Selected Label</Divider>}
                            {/* Display selected labels */}
                            <Box display="flex" flexWrap="wrap" gap={1}>
                                {selectedLabels.map((label, index) => (
                                    <Typography
                                        key={index}
                                        style={{
                                            fontSize: 12,
                                            border: "1px solid",
                                            borderRadius: 7,
                                            padding: "2px 7px",
                                            backgroundColor: label.color,
                                            color: label.fontColor,
                                            borderColor: label.color,
                                        }}
                                    >
                                        {label.label}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </>
                </Box>
            </Paper>

            {/* Add Dialog for ManageLabels */}
            <Dialog open={openLabels} onClose={() => setOpenLabels(false)} maxWidth="sm" fullWidth>
                <ManageLabels
                    lead={{
                        ...block,
                        labels: selectedLabels,
                        name: "Label Node",
                    }}
                    setLead={(updatedLead) => {
                        // This is called when labels are modified in ManageLabels
                        setSelectedLabels(updatedLead.labels || []);
                    }}
                    handleClose={handleCloseLabels}
                    setLeads={() => {}} // Empty function since we don't need to update a leads list
                />
            </Dialog>
        </>
    );
}
