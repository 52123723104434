import axios from "axios";
let API_ADDRESS = "";
if (location.hostname === "localhost1") {
    API_ADDRESS = "http://localhost:8080/";
} else {
    API_ADDRESS = process.env.REACT_APP_WHATSAPP_API_ADDRESS;
}

export default async function getState(clientId, secret) {
    console.log(clientId);

    let status = "";
    let size = 0;

    try {
        let data = JSON.stringify({
            clientId: clientId,
            from: clientId,
            secret,
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_ADDRESS}check-state`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        let config2 = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_ADDRESS}get-directory-size`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            const response = await axios.request(config);
            if (response.data.status === true) {
                status = "ready";
            } else {
                status = "disconnected";
            }
        } catch (error) {
            console.log(error);
            status = "disconnected";
        }

        try {
            const response2 = await axios.request(config2);
            console.log(response2);
            size = response2.data.size;
            return { status, size };
        } catch (error) {
            console.log(error);
            size = 0;
            return { status, size };
        }
    } catch (err) {
        console.log(err);
        status = "error";
        size = 0;
        return { status, size };
    }
}
