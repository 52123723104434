import ContactlessIcon from "@mui/icons-material/Contactless";
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";
import axios from "axios";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { db } from "../../firebase/firebase-utils";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import { GridContainer, GridFlexBox, Loading } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import { Name } from "./../../themes/themes";
import checkConnection from "./checkConnection";
import deleteClient from "./deleteClient";
import generateSecret from "./generateSecret";

let API_ADDRESS = "";
if (location.hostname === "localhost") {
    API_ADDRESS = "http://localhost:8080/";
} else {
    API_ADDRESS = process.env.REACT_APP_WHATSAPP_API_ADDRESS;
}

export default function ConnectWhatsappComponent({
    whatsappUser,
    setApiAddress = () => {},
    setWhatsappSession = () => {},
    setWhatsappPhone = () => {},
    setSecret = () => {},
}) {
    const [loading, setLoading] = useState(false);
    const [qr, setQr] = useState("");
    const [message, setMessage] = useState("");
    const [session, setSession] = useState("");
    const [api, setApi] = useState("");
    const [apiConfirmed, setApiConfirmed] = useState(false);
    const [subscribe, setSubscribe] = useState(false);
    const [secretCounter, setSecretCounter] = useState();
    const [secret_, setSecret_] = useState();
    const [clientId, setClientId] = useState("");

    useEffect(() => {
        console.log("whatsappUser: ", whatsappUser);
        if (whatsappUser) {
            if (
                whatsappUser.role === "Super Admin" ||
                whatsappUser?.addOn?.includes("whatsappCloud") ||
                whatsappUser?.superUserId
            ) {
                setApi(API_ADDRESS);
                setApiAddress(API_ADDRESS);
                getClientId();
                console.log("Using ", API_ADDRESS);
                setApiConfirmed(true);
            } else {
                setApi(API_ADDRESS);
                setApiAddress(API_ADDRESS);
                getClientId();
                setApiConfirmed(true);
            }
        }
    }, [whatsappUser]);

    useEffect(() => {
        console.log("clientId: ", clientId);
    }, [clientId]);

    const getClientId = async () => {
        if (whatsappUser.role === "Super Admin") {
            setClientId(removeSpecialChars(whatsappUser.id));
            return;
        }

        if (whatsappUser?.addOn?.includes("whatsappCloud")) {
            setClientId(removeSpecialChars(whatsappUser.id));
            return;
        }

        if (whatsappUser?.superUserId) {
            const superUserDocRef = doc(db, "users", whatsappUser.superUserId);
            const superUserDocSnap = await getDoc(superUserDocRef);
            if (!superUserDocSnap.exists()) return;
            const superUser = mapDocSnapshot(superUserDocSnap);
            if (superUser.addOn !== "whatsappCloud") return;
            setClientId(removeSpecialChars(superUser.id));
            return;
        }
    };

    useEffect(() => {
        if (!clientId) return;
        const docRef = doc(db, "whatsappClients", clientId);

        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.data();

                if (data.qr) {
                    console.log("QR code ready");
                    setMessage("Scan the QR code using your WhatsApp mobile app");
                    setQr(data.qr);
                } else {
                    setQr("");
                    setMessage("");
                    setLoading(false);
                }
            }
        });
        return unsubscribe;
    }, [clientId]);

    useEffect(() => {
        if (!clientId && !api) return;
        try {
            console.log(clientId);
            const docRef = doc(db, "whatsappClients", clientId);
            const unsubscribe = onSnapshot(docRef, async (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    setWhatsappPhone(data.phone);
                    let secret;
                    if (data.secretCounter) {
                        setSecretCounter(data.secretCounter);
                        secret = generateSecret(clientId, data.secretCounter);
                    } else {
                        await updateDoc(docRef, { secretCounter: 1 });
                        secret = generateSecret(clientId, 1);
                    }
                    if (data.status === "ready") {
                        setLoading(true);
                        const connection = await checkConnection(clientId, api, secret);
                        console.log(connection);
                        if (connection) {
                            setSession(clientId);
                            setWhatsappSession(clientId);
                            setMessage("Connected to Whatsapp");
                            setQr("");
                            setLoading(false);
                        } else {
                            setSession("");
                            setWhatsappSession("");
                            setLoading(false);
                        }
                    } else {
                        setSession("");
                        setWhatsappSession("");
                    }
                } else {
                    setSecretCounter(1);
                }
            });
            return unsubscribe;
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [clientId, api]);

    useEffect(() => {
        const secret = generateSecret(clientId, secretCounter);
        setSecret(secret);
        setSecret_(secret);
    }, [clientId, secretCounter]);

    const handleSubmitId = async () => {
        if (whatsappUser.addOn !== "whatsappCloud") {
            setMessage("Please subscribe to Professional Plan to use this feature");
            setSubscribe(true);
            return;
        }

        let data = JSON.stringify({
            clientId: clientId,
            secret: secret_,
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${api}start`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        setLoading(true);

        try {
            console.log(config);
            const response = await axios.request(config);
            console.log(response.data);
            setMessage(response.data.status);
            if (response?.data?.status?.includes("started")) {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setQr("");
            setMessage(error.message);
            setLoading(false);
        }
    };

    const confirmation = useConfirmation();

    const handleDisconnect = async () => {
        const response = await confirmation("Disconnect client", "Confirm?");
        if (response) {
            const docRef = doc(db, "whatsappClients", clientId);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) return;
            const client = { ...docSnap.data(), id: docSnap.id };
            const secretCounter = client.secretCounter || 1;
            const secret = generateSecret(client.id, secretCounter);
            await deleteClient(client.id, secret, api);
            await updateDoc(docRef, { qr: "" });
            console.log(`${client.id} disconnected`);
        }
    };

    return (
        <GridContainer>
            <GridFlexBox>
                {session ? (
                    <>
                        <Name>Ready</Name>
                        <ContactlessIcon sx={{ color: green[500] }} />
                    </>
                ) : (
                    "Not connected"
                )}
            </GridFlexBox>
            <GridFlexBox>{message}</GridFlexBox>
            {subscribe && (
                <GridFlexBox gap="8px">
                    <Name>You can subcribe</Name>
                    <Link to={"/stripe-checkout-page"}>here</Link>
                </GridFlexBox>
            )}
            <GridFlexBox gap="8px">
                <Button
                    variant="contained"
                    onClick={handleSubmitId}
                    disabled={loading || Boolean(qr)}
                    size="small"
                    sx={{ backgroundColor: green[600] }}
                >
                    Connect to Whatsapp
                </Button>
                <Button
                    variant="contained"
                    onClick={handleDisconnect}
                    disabled={loading}
                    color="error"
                    size="small"
                >
                    Disconnect
                </Button>
            </GridFlexBox>
            <Loading loading={loading} />
            <GridFlexBox>{qr && <img src={qr} />}</GridFlexBox>
        </GridContainer>
    );
}
