import { Box, Button, Chip, Container, Tab, Tabs, Typography } from "@mui/material";
import type { GridFilterModel, GridRowSelectionModel, GridSlotsComponentsProps } from "@mui/x-data-grid";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import formatNumber from "../../functions/common-functions/formatNumber";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import CommissionTable from "./CommissionTable";
import PaymentDialog from "./PaymentDialog";
import processData from "./processData";
import { DownlineInfo, UserType } from "./types";
import useColumns from "./useColumns";

export default function SalesOverviewPage() {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<any>([]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [showActive, setShowActive] = useState(false);
    const [showPaid, setShowPaid] = useState(false);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalMrr, setTotalMrr] = useState(0);
    const [totalMrc, setTotalMrc] = useState(0);
    const [openAddPayment, setOpenAddPayment] = useState(false);
    const [currentRow, setCurrentRow] = useState<any>(null);
    const [trigger, setTrigger] = useState(0);
    const [commissionTable, setCommissionTable] = useState<any>({}); // commission table
    const [currentTab, setCurrentTab] = useState(0);

    const { user } = useContext(AuthContext) as { user: UserType };

    useEffect(() => {
        if (!user) return;
        if (arrayIsEmpty(rows)) return;

        const totalRevenue = rows.reduce((acc: number, row: any) => {
            return acc + (row.totalPayment || 0);
        }, 0);

        // calculate total mrr
        const totalMrr = rows.reduce((acc: number, row: any) => {
            return acc + (row.mrr || 0);
        }, 0);

        // calculate total mrc
        const totalMrc = rows.reduce((acc: number, row: any) => {
            return acc + (row.mrc || 0);
        }, 0);

        setTotalRevenue(totalRevenue);
        setTotalMrr(totalMrr);
        setTotalMrc(totalMrc);
    }, [user, rows]);

    useEffect(() => {
        if (!user) return;

        setLoading(true);
        const loadData = async () => {
            const data = await loadEntireDownlines();
            data.sort((a, b) => b.date - a.date);
            setRows(data);
            setLoading(false);
        };

        loadData();
    }, [user]);

    useEffect(() => {
        console.log("Current row: ", currentRow);
        if (!currentRow) return;

        const result: Record<string, DownlineInfo> = {};
        const level = currentRow.uplineIds.length;
        const downline = {
            level,
            ...currentRow,
        };

        result[currentRow.id] = downline;

        const processSingleData = async () => {
            try {
                const { processedData } = await processData(result, user);
                console.log(processedData);

                // find the index of the current row and replace it with the processed data
                const index = rows.findIndex((row: any) => row.id === currentRow.id);

                console.log("index", index);
                const newRows = [...rows];
                newRows.splice(index, 1, processedData[0]);
                setRows(newRows);

                return processedData;
            } catch (error) {
                console.error("Error processing data:", error);
                return [];
            }
        };

        processSingleData();
    }, [trigger]);

    const loadEntireDownlines = useCallback(async () => {
        if (!user) return [];

        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uplineIds", "array-contains", user.id), orderBy("date", "desc"));
        const downlinesSnapshot = await getDocs(q);

        const result: Record<string, DownlineInfo> = {};

        downlinesSnapshot.forEach((doc) => {
            const data = doc.data();
            const level = data.uplineIds.length;
            const downline = {
                level,
                ...data,
            };

            result[doc.id] = downline;
        });

        // Usage
        try {
            const { processedData, commissionTable } = await processData(result, user);
            setCommissionTable(commissionTable);

            return processedData;
        } catch (error) {
            console.error("Error processing data:", error);

            return [];
        }
    }, [user]);

    // columns definition
    const columns = useColumns({ user, rows, handleAddPayment, handleAddSubscription });

    // handling functions
    const handleShowOnlyActive = () => {
        setShowActive((prev) => !prev);
    };

    const handleShowOnlyPaid = () => {
        setShowPaid((prev) => !prev);
    };

    function handleAddPayment(row: any) {
        console.log("Add payment for: ", row.id);
        setCurrentRow(row);
        setOpenAddPayment(true);
    }

    function handleAddSubscription(row: any) {
        console.log("Add subscription for: ", row.id);
    }

    const handleSeedUsersUplines = async () => {
        const collectionRef = collection(db, "users");
        const snapshot = await getDocs(collectionRef);
        const users = mapSnapshotTS<UserType>(snapshot);
        console.log(users);

        const seedUserUplines = async (user: UserType) => {
            if (!user) return;
            if (user.id === "daveckw@gmail.com") return;

            const uplineIds = [user.id];
            const uplineNames = [user.displayName];
            let uplineId = user?.referral;

            if (!uplineId) {
                const docRef = doc(db, "users", user.id);
                await updateDoc(docRef, { referral: "daveckw@gmail.com" });
                console.log("Updated referral for user: ", user.id, " to daveckw@gmail.com");
                uplineId = "daveckw@gmail.com";
            }
            let uplineName = user?.displayName;

            while (uplineId) {
                uplineIds.push(uplineId);

                const uplineRef = doc(db, "users", uplineId);
                const uplineSnapshot = await getDoc(uplineRef);
                const uplineData: UserType = { ...uplineSnapshot.data(), id: uplineSnapshot.id };
                console.log("uplineId: ", uplineData.id);
                uplineId = uplineData.referral;
                uplineName = uplineData.displayName || "no name";
                uplineNames.push(uplineName);
                if (!uplineId) break;
            }
            const docRef = doc(db, "users", user.id);
            await updateDoc(docRef, { uplineIds, uplineNames });
            console.log("Updated uplineIds for user: ", user.id, uplineIds, uplineNames);
        };

        users.forEach(async (user) => {
            await seedUserUplines(user);
        });
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    // sorting memoized rows
    const displayRows = useMemo(() => {
        let newRows = [...rows];

        if (showActive) {
            newRows = newRows.filter((row) => row.addOn === "whatsappCloud");
        }

        if (showPaid) {
            newRows = newRows.filter((row) => Boolean(row.totalPayment));
        }

        return newRows;
    }, [showPaid, showActive, rows]);

    return (
        <Container maxWidth="xl">
            <Box width="100%" height="calc(100vh - 150px)">
                <Box py={2} display="flex" alignItems={"center"} gap={1} justifyContent={"space-between"}>
                    {user?.role === "123" && (
                        <Button variant="contained" color="primary" onClick={handleSeedUsersUplines}>
                            Seed users uplines
                        </Button>
                    )}
                    <Box>
                        <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab label="Sales" />
                            <Tab label="Commissions" />
                        </Tabs>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap={1} maxWidth={"100%"} flexWrap={"wrap"}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography>Total Revenue:</Typography>
                            <Chip label={formatNumber(totalRevenue)} color="primary" size="small" />
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography>MRR:</Typography>
                            <Chip label={formatNumber(totalMrr)} color="primary" size="small" />
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography>MRC:</Typography>
                            <Chip label={formatNumber(totalMrc)} color="primary" size="small" />
                        </Box>
                    </Box>
                </Box>
                {currentTab === 0 ? (
                    <DataGrid
                        rows={displayRows}
                        columns={columns}
                        loading={loading}
                        slots={{
                            toolbar: CustomToolbar,
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rowHeight={90}
                        slotProps={{
                            toolbar: {
                                handleShowOnlyPaid,
                                handleShowOnlyActive,
                                showActive,
                                showPaid,
                                handleChange: () => console.log("Change"),
                                handleDelete: () => console.log("Delete"),
                                disabled: () => rowSelectionModel.length === 0,
                            },
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={(newFilterModel) => {
                            setFilterModel(newFilterModel);
                        }}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        sx={{
                            "& .MuiDataGrid-cell": {
                                borderRight: "1px solid grey", // Add vertical border
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                borderBottom: "1px solid grey", // Add border to the column headers
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid grey", // Add border between rows if needed
                            },
                        }}

                        // checkboxSelection
                    />
                ) : (
                    <CommissionTable commissionTable={commissionTable} />
                )}
            </Box>

            <PaymentDialog
                openAddPayment={openAddPayment}
                setOpenAddPayment={setOpenAddPayment}
                currentRow={currentRow}
                setTrigger={setTrigger}
            />
        </Container>
    );
}

interface CustomToolbarProps {
    disabled: boolean;
    showActive: boolean;
    handleDelete: () => void;
    handleChange: () => void;
    handleShowOnlyPaid: () => void;
}

declare module "@mui/x-data-grid" {
    interface ToolbarPropsOverrides {
        props: CustomToolbarProps;
    }
}

function CustomToolbar(props: NonNullable<GridSlotsComponentsProps["toolbar"]>) {
    const { user } = useContext(AuthContext) as { user: UserType };
    const {
        showActive,
        showPaid,
        handleShowOnlyActive,
        handleShowOnlyPaid,
        handleDelete,
        handleChange,
        disabled = true,
    } = props;

    return (
        <GridToolbarContainer>
            <GridToolbar />
            <GridToolbarQuickFilter />
            <Button
                variant="contained"
                onClick={handleShowOnlyActive}
                size="small"
                color={showActive ? "secondary" : "primary"}
            >
                {showActive ? "Show All" : "Show Active"}
            </Button>
            <Button
                variant="contained"
                onClick={handleShowOnlyPaid}
                size="small"
                color={showPaid ? "secondary" : "primary"}
            >
                {showPaid ? "Show All" : "Show Paid"}
            </Button>

            {/* Change display to flex to enable the buttons */}
            <Box display="none" alignItems="center" pt="4px">
                <Button color="error" onClick={handleDelete} disabled={disabled} size="small">
                    Delete
                </Button>
                {user?.role === "Super Admin" && (
                    <Button color="warning" onClick={handleChange} disabled={disabled} size="small">
                        Add/Edit Field
                    </Button>
                )}
            </Box>
        </GridToolbarContainer>
    );
}
