import { Box, CircularProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
    collection,
    collectionGroup,
    limit,
    onSnapshot,
    orderBy,
    query,
    Timestamp,
    where
} from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useWindowSize from "../../hooks/useWindowSize";

export default function WhatsappTimeReport() {
    const [messages, setMessages] = useState([]);
    const [subMessages, setSubMessages] = useState([]);
    const [currentChatId, setCurrentChatId] = useState("");
    const [hourlyMessageCounts, setHourlyMessageCounts] = useState({});
    const [dailyMessageCounts, setDailyMessageCounts] = useState({});

    const [startDate, setStartDate] = useState(null); // Start date for filtering
    const [endDate, setEndDate] = useState(null); // End date for filtering

    const [grouping, setGrouping] = useState("hour"); // New state to manage grouping (hour or day)
    const [messageType, setMessageType] = useState("all");

    const [selectedClientId, setSelectedClientId] = useState("All Clients");
    const [clientIds, setClientIds] = useState([]);

    const [loading, setLoading] = useState(true); // State to track loading status

    const { user } = useContext(AuthContext);


     const windowSize = useWindowSize();

     // Memoize the isMobile value to optimize performance
     const isMobile = useMemo(() => {
         return windowSize.width && windowSize.width < 600;
     }, [windowSize.width]);

    useEffect(() => {
        const today = dayjs(); // Use dayjs for today's date
        const isAdmin = user.role === "Admin" || user.role === "Super Admin";

        // Set the pastDate based on the user's role
        const pastDate = isAdmin ? today.subtract(365, "day") : today.subtract(30, "day"); // Subtract 30 days using dayjs

        setStartDate(pastDate);
        setEndDate(today);
        console.log("Past Date:", pastDate.format("YYYY-MM-DD")); // Format for readability
        console.log("Today:", today.format("YYYY-MM-DD")); // Format for readability
    }, [user]);

    useEffect(() => {
        // Ensure startDate and endDate are dayjs objects and valid
        if (!startDate || !endDate) {
            console.error("Invalid dates");
            return;
        }

        const adjustedStartDate = startDate.startOf("day");
        const adjustedEndDate = endDate.endOf("day");

        const collectionRef = collectionGroup(db, "messages");

        let q = query(
            collectionRef,
            where("date", ">=", Timestamp.fromDate(adjustedStartDate.toDate())),
            where("date", "<=", Timestamp.fromDate(adjustedEndDate.toDate())),

            orderBy("date", "desc"),
            limit(2000)
        );

        // Adjust query conditions based on user role
        const isAdmin = user.role === "Admin" || user.role === "Super Admin";

        if (isAdmin) {
            if (selectedClientId === "All Clients") {
                console.log("All Clients selected")
               
            }
            else {

                 q = query(q, where("clientId", "==", selectedClientId));
            }

            // Admin or super admin: No additional filtering, just apply the date range
            // `q` already contains the date range and limit, so no changes needed here
        } else {
            // Regular user: Filter by `clientId` and apply the 30-day range
            q = query(
                q, // previous query conditions
                where("clientId", "==", user.clientId) // Additional condition for regular users
            );
        }

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const allMessages = snapshot.docs.map((doc) => {
                const data = doc.data();
                const chatRoomId = data.chatRoomId;
                const myNumber = chatRoomId ? chatRoomId.split("-")[0] : null; // Extract the first part of chatRoomId
                return {
                    id: doc.id,
                    chatRoomId,
                    myNumber, // Store extracted number
                    ...data,
                };
            });

            console.log("All Messages:", allMessages);

            // Filter messages based on messageType
            const filteredMessages =
                messageType === "incoming"
                    ? allMessages.filter((msg) => msg.to.includes(msg.myNumber))
                    : allMessages;

            // Process messages by hour or day based on `grouping`
            if (grouping === "hour") {
                processMessagesByHour(filteredMessages);
            } else {
                processMessagesByDay(filteredMessages);
            }

            setMessages(filteredMessages);
        });

        return unsubscribe;
    }, [startDate, endDate, grouping, messageType, selectedClientId, user.role, user]);

    // useEffect(() => {
    //     // Ensure startDate and endDate are dayjs objects and valid
    //     if (!startDate || !endDate) {
    //         console.error("Invalid dates");
    //         return;
    //     }

    //     // Adjust startDate to the start of the day and endDate to the end of the day
    //     const adjustedStartDate = startDate.startOf("day");
    //     const adjustedEndDate = endDate.endOf("day");

    //     const collectionRef = collection(db, "whatsappMessages");

    //     // Check user role (admin/super admin or regular user)
    //     const isAdmin = user.role === "Admin" || user.role === "Super Admin";

    //     let q;
    //     if (isAdmin) {
    //         // If user is admin or super admin, load all messages (limit to 2000)
    //         q = query(collectionRef, orderBy("date", "desc"));
    //     } else {
    //         // For regular users, filter by clientId
    //         q = query(collectionRef, where("clientId", "==", user.clientId), orderBy("date", "desc"));
    //     }

    //     const unsubscribe = onSnapshot(q, async (snapshot) => {
    //         const allMessages = await Promise.all(
    //             snapshot.docs.map(async (doc) => {
    //                 const messageSubCollectionRef = collection(doc.ref, "messages");
    //                 let subCollectionQuery = query(
    //                     messageSubCollectionRef,
    //                     where("date", ">=", Timestamp.fromDate(adjustedStartDate.toDate())),
    //                     where("date", "<=", Timestamp.fromDate(adjustedEndDate.toDate())),
    //                     orderBy("date", "desc"),
    //                     limit(2000)
    //                 );

    //                 // If a clientId is selected (and user is admin), filter by selected clientId
    //                 if (selectedClientId) {
    //                     subCollectionQuery = query(
    //                         subCollectionQuery,
    //                         where("clientId", "==", selectedClientId)
    //                     );
    //                 }

    //                 const subCollectionSnapshot = await getDocs(subCollectionQuery);

    //                 const subMessages = subCollectionSnapshot.docs.map((subDoc) => {
    //                     const data = subDoc.data();
    //                     const chatRoomId = data.chatRoomId; // Get chatRoomId from the subdocument
    //                     const myNumber = chatRoomId ? chatRoomId.split("-")[0] : null; // Split chatRoomId to get the first part
    //                     return {
    //                         id: subDoc.id,
    //                         chatRoomId,
    //                         myNumber, // Store the split result in the message
    //                         ...data,
    //                     };
    //                 });

    //                 return {
    //                     id: doc.id,

    //                     messages: subMessages,
    //                 };
    //             })
    //         );

    //         setMessages(allMessages);
    //         console.log(allMessages);

    //         const filteredMessages =
    //             messageType === "incoming"
    //                 ? allMessages.map((msg) => ({
    //                       ...msg,
    //                       messages: msg.messages.filter((subMsg) => subMsg.to.includes(subMsg.myNumber)),
    //                   }))
    //                 : allMessages;

    //         // Determine whether to process by hour or day based on `grouping`
    //         if (grouping === "hour") {
    //             processMessagesByHour(filteredMessages);
    //         } else {
    //             processMessagesByDay(filteredMessages);
    //         }
    //     });

    //     return unsubscribe;
    // }, [startDate, endDate, grouping, messageType, selectedClientId, user.role, user]);

    useEffect(() => {
        if (user.role === "Admin" || user.role === "Super Admin") {
            // Listen to real-time updates for client IDs only if the user is admin or superadmin
            const unsubscribe = onSnapshot(
                collection(db, "whatsappMessages"), // Replace "clients" with the actual collection name
                (snapshot) => {
                    const clientList = snapshot.docs.map((doc) => {
                        return doc.data().clientId; // Access the clientId field
                    });

                    const uniqueClientList = [...new Set(clientList)];
                    // Assuming clientId is the document ID
                    setClientIds(uniqueClientList);
                    setLoading(false);
                    console.log(uniqueClientList);
                },
                (error) => {
                    console.error("Error loading client IDs:", error);
                    setLoading(false);
                }
            );

            // Clean up listener on unmount or when role changes
            return () => unsubscribe();
        } else {
            // If not an admin or superadmin, disable the Select component and clear clientIds
            setClientIds([user.clientId]);
            setSelectedClientId(user.clientId);
            setLoading(false);
        }
    }, [user.role]);

    const processMessagesByHour = (allMessages) => {
        // Create an object to count messages in each hour group
        const hourGroups = Array.from({ length: 24 }, () => 0); // Array to hold counts from 0 AM to 23 PM

        allMessages.forEach((message) => {
            // No need to loop through `message.messages` anymore since `allMessages` now directly contain `subMsg` data.
            const date = message.date.toDate(); // Convert Firestore timestamp to JavaScript Date
            const hour = date.getHours(); // Extract hour from date
            hourGroups[hour] += 1; // Increment count for that hour group
        });

        // Convert the array into an object with labeled hour keys (1AM, 2AM, etc.)
        const hourlyCounts = hourGroups.reduce((acc, count, index) => {
            // Format the hour label
            let hourLabel;
            if (index === 0) {
                hourLabel = "12AM";
            } else if (index === 12) {
                hourLabel = "12PM";
            } else if (index < 12) {
                hourLabel = `${index}AM`;
            } else {
                hourLabel = `${index - 12}PM`;
            }

            acc[hourLabel] = count;
            return acc;
        }, {});

        setHourlyMessageCounts(hourlyCounts);
        console.log("Hourly message counts:", hourlyCounts); // For debugging
    };

    const processMessagesByDay = (allMessages) => {
        const dayGroups = {
            Sunday: 0,
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
        };

        allMessages.forEach((message) => {
            const date = message.date.toDate();
            const day = date.toLocaleString("en-US", { weekday: "long" });
            dayGroups[day] += 1;
        });

        setDailyMessageCounts(dayGroups);
    };

    // Define columns for the DataGrid
    const columns = [
        {
            field: grouping === "hour" ? "hour" : "day",
            headerName: grouping === "hour" ? "Hour" : "Day",
            width: 150,
        },
        { field: "count", headerName: "Message Count", width: 150 },
    ];

    const rows = Object.entries(grouping === "hour" ? hourlyMessageCounts : dailyMessageCounts).map(
        ([label, count], index) => ({
            id: index,
            [grouping === "hour" ? "hour" : "day"]: label,
            count,
        })
    );

    const chartData = Object.entries(grouping === "hour" ? hourlyMessageCounts : dailyMessageCounts).map(
        ([label, count]) => ({
            [grouping === "hour" ? "hour" : "day"]: label,
            count,
        })
    );

    // Define columns for the DataGrid to display messages by clientId, date, and body
    const messageColumns = [
        // { field: "clientId", headerName: "Client ID", width: 150 },
        { field: "date", headerName: "Date", width: 200 },
        { field: "from", headerName: "From", width: 400 },
        { field: "to", headerName: "To", width: 400 },
    ];

    const messageRows = messages.map((message, index) => ({
        id: `${message.id}-${index}`,
        // clientId: message.data.clientId || "Unknown",
        date: message.date.toDate().toLocaleString(),
        // body: subMsg.body || "No content",
        from: message.from || "No content",
        to: message.to || "No content",
    }));

    return (
        <Box>
            {/* Render the column chart or other UI components here */}
            <Box>
                <Typography sx={{ fontWeight: "bold", fontSize: "30px" }}>
                    Messages Reply Time Analysis
                </Typography>
            </Box>
            <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" ml={4} mt={5}>
                    <Typography mr={1}>Date Range:</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box>
                            <DatePicker
                                label="Date From"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                                // renderInput={(params: TextFieldProps) => <TextField {...params} />}
                            />
                        </Box>
                        <Box ml={4}>
                            <DatePicker
                                label="Date To"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                                // renderInput={(params: TextFieldProps) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Typography ml={4} mr={1}>
                        Group By:
                    </Typography>
                    <Select value={grouping} onChange={(e) => setGrouping(e.target.value)}>
                        <MenuItem value="hour">Hour</MenuItem>
                        <MenuItem value="day">Day</MenuItem>
                    </Select>
                    <Typography ml={4} mr={1}>
                        Message Type:
                    </Typography>
                    <Select value={messageType} onChange={(e) => setMessageType(e.target.value)}>
                        <MenuItem value="all">All Messages</MenuItem>
                        <MenuItem value="incoming">Incoming Only</MenuItem>
                    </Select>
                    <Typography ml={4} mr={1}>
                        ClientId:
                    </Typography>
                    <Select
                        value={selectedClientId}
                        onChange={(e) => setSelectedClientId(e.target.value)}
                        // variant="outlined"
                        // label="Select Client"
                        disabled={user.role !== "Admin" && user.role !== "Super Admin"}
                    >
                        <MenuItem value="All Clients">All Clients</MenuItem>

                        {loading ? (
                            <MenuItem disabled>
                                <CircularProgress size={24} />
                            </MenuItem>
                        ) : (
                            clientIds.map((clientId) => (
                                <MenuItem key={clientId} value={clientId}>
                                    {clientId}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </Box>
            </Box>

            {/* Display the graph */}
            <Box mt={5}>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={grouping === "hour" ? "hour" : "day"} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" fill="#26BDC2" />
                    </BarChart>
                </ResponsiveContainer>
            </Box>

            {/* <Box>
                {Object.entries(hourlyMessageCounts).map(([hour, count]) => (
                    <Box key={hour}>
                        {hour}: {count} messages
                    </Box>
                ))}
            </Box> */}

            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                autoHeight
                disableSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: {
                            fileName: "message analysis",
                            delimiter: ",",
                            utf8WithBom: true,
                        },
                    },
                }}
            />
            {/* Pass `hourlyMessageCounts` to your chart component */}

            <Box>
                <Typography sx={{ fontWeight: "bold", mt: 5 }}>Messages Detail Table</Typography>
                <DataGrid
                    rows={messageRows}
                    columns={messageColumns}
                    pageSize={10}
                    autoHeight
                    disableSelectionOnClick
                />
            </Box>
        </Box>
    );
}
