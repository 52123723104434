import { Avatar, Box, Button, Icon, IconButton, Typography } from "@mui/material";
import { collection, doc, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { IFlow } from "../../../functions/src/types/flowType";
import { UserType } from "../../../functions/src/types/userTypes";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useWindowSize from "../../hooks/useWindowSize";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import { ItemComponent } from "./ItemComponent";
import SubItemDetailsComponent from "./SubItemDetailsComponent";
import SubItemsComponent from "./SubItemsComponent";
import { IIntentionBlock } from "../../../functions/src/types/intentionType";

export default function IntentionsPage(): React.JSX.Element {
    const [items, setItems] = useState<IFlow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [columnWidths, setColumnWidths] = useState<number[]>([10, 40, 40]);
    const [columnDisplay, setColumnDisplay] = useState<number[]>([1, 1, 1]);
    const [currentColumn, setCurrentColumn] = useState<number>(0);

    const [currentItemId, setCurrentItemId] = useState<string>("");
    const [currentItem, setCurrentItem] = useState<IFlow | null>(null);

    const [currentSubItem, setCurrentSubItem] = useState<IIntentionBlock | null>(null);

    const { user } = useContext(AuthContext) as { user: UserType };

    const windowSize = useWindowSize();

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (isMobile) {
            switch (currentColumn) {
                case 0: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
                case 1: {
                    setColumnDisplay([0, 1, 0]);
                    setColumnWidths([0, 100, 0]);
                    break;
                }
                case 2: {
                    setColumnDisplay([0, 0, 1]);
                    setColumnWidths([0, 0, 100]);
                    break;
                }
                default: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
            }
        } else {
            setColumnDisplay([1, 1, 1]);
            setColumnWidths([20, 40, 40]);
        }
    }, [isMobile, currentColumn]);

    // Load settings on user login
    useEffect(() => {
        if (!user) return;

        const docRef = doc(db, "users", user.id);
        const whatsappFlowsRef = collection(docRef, "whatsappFlows");

        const q = query(whatsappFlowsRef, orderBy("date", "desc"));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            let flows = mapSnapshotTS<IFlow>(querySnapshot);

            setItems(flows);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!currentItemId) {
            setCurrentItem(null);
            setCurrentSubItem(null);
            return;
        }

        const docRef = doc(db, "users", user.id, "whatsappFlows", currentItemId);
        const unsubscribe = onSnapshot(docRef, (doc: any) => {
            const data = mapDocSnapshot(doc);

            setCurrentItem(data);
            console.log("Data ", data);
        });

        setCurrentSubItem(null);

        return unsubscribe;
    }, [currentItemId]);

    return (
        <Box display="flex" width={"100vw"} height={`calc(100vh - 55px)`}>
            {/* First column */}
            <Box
                display={columnDisplay[0] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[0] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    p={1}
                    py={2}
                    width={"100%"}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box display="flex" alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(1)}>
                                <Icon>settings</Icon>
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection={"column"}
                    width="100%"
                    sx={{ overflowY: "auto" }}
                    // onScroll={handleScroll} // Add the scroll handler here
                >
                    {items.map((item) => (
                        <Box key={item?.id} width={"100%"}>
                            <ItemComponent
                                item={item}
                                selected={currentItemId === item?.id}
                                handleClick={() => {
                                    setCurrentColumn(1);
                                    setCurrentItemId(item?.id || "");
                                    console.log(item);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Second column */}
            <Box
                display={columnDisplay[1] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[1] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(0)}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        )}
                        <Avatar />
                        <Typography>{currentItem?.name || ""}</Typography>
                    </Box>
                    <Box>
                        {/* Button to enter third column */}
                        {isMobile && (
                            <Box display="flex" justifyContent="center" p={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setCurrentItemId(currentItemId);
                                        setCurrentColumn(2);
                                    }}
                                    sx={{ width: "100%" }} // Make the button full width
                                >
                                    View Details
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    width="100%"
                    height="100%"
                    sx={{
                        backgroundSize: "cover", // to cover the entire Box area
                        backgroundPosition: "center",
                        overflowY: "auto",
                    }}
                >
                    <Box p={1} width={"100%"}>
                        <SubItemsComponent currentItemId={currentItemId} setSubItem={setCurrentSubItem} />
                    </Box>
                </Box>
            </Box>

            {/* Third column */}
            <Box
                display={currentItemId ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[2] + "%"}
                height={"100%"}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(1)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    <Box display="flex" alignContent={"center"} gap={1} justifyContent={"flex-start"}>
                        <Typography>{`Details`}</Typography>
                        {currentSubItem && (
                            <Typography
                                sx={{ fontWeight: "bold" }}
                            >{`for ${currentSubItem?.title}`}</Typography>
                        )}
                    </Box>
                </Box>

                <Box m={1}>
                    {currentSubItem && (
                        <SubItemDetailsComponent
                            currentSubItem={currentSubItem as IIntentionBlock}
                            currentItemId={currentItemId}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
