import { DocumentSnapshot } from "firebase-admin/firestore";

export default function mapDocSnapshotTS<T>(snapshot: DocumentSnapshot): T | null {
    if (snapshot.exists) {
        const data = {
            ...snapshot.data(),
            id: snapshot.id,
        } as T & { id: string };

        return data;
    }

    return null;
}
