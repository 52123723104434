import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import { signIn } from "../functions/signIn";
import { COPYRIGHT_LINK, WEBSITE_NAME } from "../settings/settings";
import { ButtonC, GridFlexBox, Loading } from "../themes/themes";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href={COPYRIGHT_LINK}>
                {WEBSITE_NAME}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default function SignInPage() {
    const [loading, setLoading] = useState(false);
    const { setUser, setAuthUser } = useContext(AuthContext);
    const { setSettings } = useContext(StoreContext);

    const { openNotification } = useContext(NotificationContext);

    const navigate = useNavigate();
    let { path, path2 } = useParams();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const state = {
            email: data.get("email"),
            password: data.get("password"),
        };
        setLoading(true);
        const user = await signIn(state, setUser, setAuthUser, openNotification);

        setLoading(false);
        if (user) {
            openNotification("Signed in successfully", "success");
            path = path ? path + "/" : "";
            path2 = path2 ? path2 + "/" : "";
            const addPath = path + path2;
            setSettings({});
            navigate("/" + addPath);
        }
    };

    const handleForgotPassword = () => {
        navigate("/forgot-password-page");
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid container>
                        <GridFlexBox mtb1>
                            <TextField name="email" label="Email Address" fullWidth type="email" />
                        </GridFlexBox>
                        <GridFlexBox mtb1>
                            <TextField name="password" label="Password" fullWidth type="password" />
                        </GridFlexBox>
                    </Grid>
                    <Loading loading={loading} />
                    <ButtonC submit fullWidth mtb1>
                        Sign In
                    </ButtonC>
                    <Grid container>
                        <Grid item xs>
                            <Link href="" variant="body2" onClick={handleForgotPassword}>
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="sign-up" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
