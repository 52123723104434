import AddIcon from "@mui/icons-material/Add";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
    arrayRemove,
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FullDialog from "../../components/FullDialog";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useList from "../../context-utils/ListContext";
import { AuthContext } from "../../context/AuthContext";
import { LeadsContext } from "../../context/LeadsContext";
import { db } from "../../firebase/firebase-utils";
import useChooseFriend from "../../hooks/useChooseFriend";
import useColors from "../../hooks/useColors";
import { GridContainer, GridFlexBox, Loading, Name } from "../../themes/themes";
import formatDate from "../../utils-functions/formatDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import LeadCardPage from "../LeadCardPage";
import { arrayIsEmpty } from "./../../utils-functions/arrayIsEmpty";
import convertDate from "./../../utils-functions/convertDate";
import formatNumber from "./../../utils-functions/formatNumber";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import getLeadsByPageId from "./getLeadsByPageId";
import LeadActionsComponent from "./LeadActionsComponent";
import ManageLabels from "./ManageLabels";

export default function LeadsTablePageV3() {
    const { user } = useContext(AuthContext);
    const {
        sourceLeads,
        setSourceLeads,
        assignedLeads,
        setAssignedLeads,
        reload,
        setReload,
        year,
        setYear,
        currentPageId,
        setCurrentPageId,
        pages,
        setPages,
    } = useContext(LeadsContext);

    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [leadsType, setLeadsType] = useState(0);
    const [loadAll, setLoadAll] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false, email: false });
    const [currentLead, setCurrentLead] = useState(null);
    const [openLabel, setOpenLabel] = useState(false);
    const [openCard, setOpenCard] = useState(false);
    const [type, setType] = useState("SOURCE_LEADS");
    const [hideAssignedLeads, setHideAssignedLeads] = useState(true);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0 });
    const [labelsListAssigned, setLabelsListAssigned] = useState([]);
    const [labelsListSource, setLabelsListSource] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        if (leadsType === 0) {
            if (sourceLeads?.length === 0) setReload(true);
            setType("SOURCE_LEADS");
        } else {
            if (assignedLeads?.length === 0) setReload(true);
            setType("ASSIGNED_LEADS");
        }
    }, [leadsType]);

    useEffect(() => {
        setRowSelectionModel([]);
    }, [hideAssignedLeads]);

    useEffect(() => {
        if (!user) return;

        const loadPages = async () => {
            const collectionRef = collection(db, "pages");
            const q = query(collectionRef, where("admins", "array-contains", user.id));
            const snapshot = await getDocs(q);
            const pages = mapSnapshot(snapshot);
            setPages([{ name: "[none]", id: "" }, { name: "[ALL]", id: "all" }, ...pages]);
        };
        loadPages();
    }, []);

    useEffect(() => {
        // LOAD LEADS
        const loadLeads = async () => {
            //
            setLoading(true);

            let setLabelsList = setLabelsListSource;
            if (type === "ASSIGNED_LEADS") {
                setLabelsList = setLabelsListAssigned;
            }

            const leads = await getLeadsByPageId({
                pageId: currentPageId,
                user,
                loadAll,
                type,
                year,
                setLabelsList,
            });

            if (type === "SOURCE_LEADS") {
                setSourceLeads(leads);
            } else {
                setAssignedLeads(leads);
            }
            setReload(false);
            setLoadAll(false);
            setLoading(false);
        };

        if (reload === false) return;

        loadLeads();
        //
    }, [currentPageId, loadAll, reload, type]);

    const colors = useColors();

    const handleDeleteAssignment = async (lead, index) => {
        const response = await confirmation(
            "Delete assignment",
            "Are you sure you want to delete this assignment?"
        );

        if (!response) return;

        const docRef = doc(db, "leads", lead.id);
        const newAssignments = [...lead.assignments];
        const assignment = newAssignments[index];
        const email = assignment?.assign?.email || "";
        newAssignments.splice(index, 1);

        await updateDoc(docRef, { assignments: newAssignments, warriors: arrayRemove(email) });

        const warriors = lead.warriors ? lead.warriors.filter((warrior) => warrior !== email) : [];

        if (type === "SOURCE_LEADS") {
            setSourceLeads((prev) => {
                const index = prev.findIndex((l) => l.id === lead.id);
                const newSourceLeads = [...prev];
                newSourceLeads.splice(index, 1, { ...lead, assignments: newAssignments, warriors });
                return newSourceLeads;
            });
        } else {
            setAssignedLeads((prev) => {
                const index = prev.findIndex((l) => l.id === lead.id);
                const newAssignedLeads = [...prev];
                newAssignedLeads.splice(index, 1);
                return newAssignedLeads;
            });
        }
    };

    const handleFilterByLabel = (label) => {
        setLabels((prev) => {
            const newLabels = prev ? [...prev, label] : [label];
            return newLabels;
        });
    };

    const renderAssignments = (params) => {
        let assignments = [];
        if (params.row.admins && params.row.admins.includes(user.id)) {
            if (params.row.assignments) {
                assignments = assignments.concat(params.row.assignments); // Update assignments with concatenated values
            }
        } else {
            if (params.row.assignments) {
                params.row.assignments.forEach((assignment) => {
                    if (assignment.assign.email === user.email) {
                        assignments.push(assignment);
                    }
                });
            }
        }

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "52px",
                    overflowY: "auto",
                }}
            >
                {!arrayIsEmpty(assignments) &&
                    assignments.map((assignment, i) => (
                        <Box
                            display={"flex"}
                            alignItems="center"
                            gap={1}
                            key={`${assignment.assign?.email || ""}${i}`}
                            pb={"4px"}
                        >
                            <Box display="flex" flexDirection={"column"}>
                                <Box
                                    sx={{
                                        backgroundColor: colors.chipColor,
                                        borderRadius: "5px",
                                        padding: "2px 5px",
                                    }}
                                >
                                    <Name fs10>{assignment.assign?.name}</Name>
                                </Box>
                                <Name fs8>{formatDate(convertDate(assignment.date), true)}</Name>
                            </Box>
                            <IconButton size="small" onClick={() => handleDeleteAssignment(params.row, i)}>
                                <Icon fontSize="small">clear</Icon>
                            </IconButton>
                        </Box>
                    ))}
            </Box>
        );
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: type === "SOURCE_LEADS" ? "created" : "assignedDate",
            headerName: "Date",
            width: 100,
            valueGetter: (value) => convertDate(value),
            renderCell: (params) => {
                const dateTime = formatDate(params.value, true);
                const date = dateTime.split(" ")[0];
                const time = dateTime.split(" ")[1];
                return (
                    <Box>
                        <Typography fontSize="11px">{date}</Typography>
                        <Typography fontSize={"10px"}>{time}</Typography>
                    </Box>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            renderCell: (params) => (
                <Box p={1} display="flex" alignItems={"center"}>
                    <Chip
                        label={params.value}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            console.log(params.row);
                            setCurrentLead(params.row);
                            setOpenCard(true);
                        }}
                        color={params.row.type === "ASSIGNED_LEAD" ? "primary" : "warning"}
                        size="small"
                    />
                </Box>
            ),
        },
        { field: "email", headerName: "Email", width: 180 },
        { field: "phone", headerName: "Phone", width: 150 },
        {
            field: "labels",
            headerName: "Labels",
            width: 180,
            valueGetter: (value) => {
                if (value) {
                    let labelsString = "";
                    value.forEach((label) => {
                        labelsString += label.label + ", ";
                    });
                    return labelsString;
                }
            },
            renderCell: (params) => (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "52px",
                        overflowY: "auto",
                    }}
                >
                    {!arrayIsEmpty(params.row.labels) &&
                        params.row.labels.map((label, i) => (
                            <Box
                                sx={{
                                    backgroundColor: label.color,

                                    borderRadius: "5px",
                                    padding: "2px 5px",
                                    margin: "2px",
                                }}
                                key={`${label.label || ""}${i}`}
                            >
                                <Name
                                    fs10
                                    color={label.fontColor}
                                    onClick={() => {
                                        handleFilterByLabel(label.label);
                                    }}
                                    cp
                                >
                                    {label.label}
                                </Name>
                            </Box>
                        ))}
                    <IconButton
                        onClick={() => {
                            setCurrentLead(params.row);
                            setOpenLabel(true);
                        }}
                        size="small"
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            ),
        },
        {
            field: "remarks",
            headerName: "Remarks",
            editable: true,
            width: 200,
        },
        {
            field: "lastAction",
            headerName: "Last Action",
            width: 300,
            valueGetter: (value) =>
                value ? `${formatDate(convertDate(value.created), true)} - ${value.action}` : "",
            renderCell: (params) => <LeadActionsComponent lead={params.row} leadsMode={type} />,
        },
        {
            field: "assignments",
            headerName: "Assigned To",
            width: 150,
            valueGetter: (value) =>
                value?.length ? value.map((assignment) => assignment.assign.name).join(", ") : "",
            renderCell: (params) => renderAssignments(params),
        },
        { field: "assignBy", headerName: "Assigned By", width: 140 },
        { field: "pageName", headerName: "Page", width: 200 },
        { field: "campaignName", headerName: "Ad Set", width: 140 },
        { field: "source", headerName: "Source", width: 140 },
    ];

    const handleChangePage = (e) => {
        setReload(true);
        setCurrentPageId(e.target.value);
    };

    const handleChangeType = (event, newValue) => {
        setLeadsType(newValue);
    };

    const handleSetLoadAll = () => {
        setLoadAll(true);
        setReload(true);
    };

    const confirmation = useConfirmation();

    const deleteLeads = async () => {
        const response = await confirmation("Delete leads", "Are you sure you want to delete these leads?");
        if (!response) return;

        const collectionRef = collection(db, "leads");

        rowSelectionModel.forEach(async (id) => {
            const foundLead = leads.find((lead) => lead.id === id);
            console.log("foundLead", foundLead);
            if (foundLead.admins && foundLead.admins.includes(user.id)) {
                await deleteDoc(doc(collectionRef, id));
                console.log("deleted lead", id);
            } else {
                await setDoc(doc(collectionRef, id), { warriors: arrayRemove(user.id) }, { merge: true });
                console.log("removed warrior from lead", id);
            }
        });

        setSourceLeads((prev) => prev.filter((lead) => !rowSelectionModel.includes(lead.id)));
        setAssignedLeads((prev) => prev.filter((lead) => !rowSelectionModel.includes(lead.id)));
    };

    const leads = useMemo(() => {
        if (type === "SOURCE_LEADS") {
            //
            let newSourceLeads = sourceLeads ? [...sourceLeads] : [];

            if (!arrayIsEmpty(labels)) {
                newSourceLeads = newSourceLeads.filter((lead) => {
                    if (lead.labels) {
                        return labels.every((label) => lead.labels.map((l) => l.label).includes(label));
                    }
                });
            }

            if (hideAssignedLeads) {
                return (
                    newSourceLeads?.filter((lead) => {
                        return lead?.type !== "ASSIGNED_LEAD";
                    }) || []
                );
            }

            return newSourceLeads || [];
        } else {
            let newAssignedLeads = assignedLeads ? [...assignedLeads] : [];
            if (!arrayIsEmpty(labels)) {
                newAssignedLeads = newAssignedLeads.filter((lead) => {
                    if (lead.labels) {
                        return labels.every((label) => lead.labels.map((l) => l.label).includes(label));
                    }
                });
            }
            return newAssignedLeads || [];
        }
    }, [type, sourceLeads, assignedLeads, hideAssignedLeads, labels]);

    const navigate = useNavigate();

    const handleFixAssignedLeads = () => {
        navigate("/fix");
    };

    const friend = useChooseFriend();

    const handleAssignLeads = async () => {
        const response = await friend();
        if (!response) return;

        // Temporary storage for changes to be applied in bulk after the loop
        let bulkUpdates = [];

        for (const id of rowSelectionModel) {
            const docRef = doc(db, "leads", id);
            const assignment = {
                assign: { email: response.id, name: response.displayName },
                assignBy: user.id,
                date: new Date(),
            };

            // Update sourceLead
            const lead = { ...leads.find((lead) => lead.id === id) };

            let assignments = lead.assignments ? [...lead.assignments] : [];
            if (!arrayIsEmpty(assignments)) {
                // remove all previous assignment where assign.email is equal to response.id
                assignments = assignments.filter((assignment) => assignment.assign.email !== response.id);

                // Add new assignment
                assignments.push(assignment);
            } else {
                assignments = [assignment];
            }

            lead.assignments = assignments;

            console.log(lead);
            // Ensure warriors do not contain duplicates
            lead.warriors = lead.warriors ? [...new Set([...lead.warriors, response.id])] : [response.id];
            lead.assignedDate = new Date();

            // Update Firestore document
            await updateDoc(docRef, {
                assignments,
                warriors: lead.warriors,
                assignedDate: lead.assignedDate,
            });

            // Add to bulk updates for local state
            bulkUpdates.push(lead);
        }

        // Apply all updates in bulk
        setSourceLeads((prev) => {
            // Map through the current source leads and apply updates
            return prev.map((lead) => {
                const updatedLead = bulkUpdates.find((update) => update.id === lead.id);
                return updatedLead ? updatedLead : lead;
            });
        });

        toast.success("Leads assigned successfully");
    };

    const handleClickRefresh = () => {
        setReload(true);
    };

    const handleUpdateLead = (lead) => {
        if (type === "SOURCE_LEADS") {
            setSourceLeads((prev) => {
                const index = prev.findIndex((l) => l.id === lead.id);
                const newSourceLeads = [...prev];
                newSourceLeads.splice(index, 1, lead);
                return newSourceLeads;
            });
        } else {
            const index = assignedLeads.findIndex((l) => l.id === lead.id);
            setAssignedLeads((prev) => {
                const newAssignedLeads = [...prev];
                newAssignedLeads.splice(index, 1, lead);
                return newAssignedLeads;
            });
        }
    };

    const handleChangePaginationModel = (e) => {
        setPaginationModel((prev) => ({ ...prev, page: e.target.value }));
    };

    const handleProcessRowUpdate = async (updatedRow) => {
        const remarks = updatedRow.remarks || "";
        const docRef = doc(db, "leads", updatedRow.id);
        await updateDoc(docRef, { remarks });

        if (type === "SOURCE_LEADS") {
            setSourceLeads((prev) => {
                const index = prev.findIndex((lead) => lead.id === updatedRow.id);
                const newSourceLeads = [...prev];
                newSourceLeads.splice(index, 1, { ...updatedRow, remarks });
                return newSourceLeads;
            });
        } else {
            setAssignedLeads((prev) => {
                const index = prev.findIndex((lead) => lead.id === updatedRow.id);
                const newAssignedLeads = [...prev];
                newAssignedLeads.splice(index, 1, { ...updatedRow, remarks });
                return newAssignedLeads;
            });
        }

        return updatedRow;
    };

    const handleProcessRowUpdateError = (params, error) => {
        console.log(error);
    };

    const totalPages = useMemo(() => {
        return Math.ceil(leads.length / paginationModel.pageSize);
    }, [leads]);

    const handleClickBack = () => {
        setYear((prev) => prev - 1);
        setReload(true);
    };

    const handleClickForward = () => {
        setYear((prev) => prev + 1);
        setReload(true);
    };

    const list = useList();

    const handleRemoveAdmin = async () => {
        console.log("Remove admin");
        const newLeads = [];
        let admins = [];

        rowSelectionModel.forEach((id) => {
            const found = sourceLeads.find((lead) => lead.id === id);
            if (found) {
                newLeads.push(found);
                admins = admins.concat(found.admins);
            }
        });

        // remove duplicated admins
        const uniqueAdmins = [...new Set(admins)];

        const response = await list(
            uniqueAdmins.map((admin) => ({ email: admin, name: admin })),
            "Remove Admin",
            "email",
            "face",
            false,
            true
        );

        if (!response) return;

        const email = response.email;

        setLoading(true);
        await Promise.all(
            rowSelectionModel.map(async (id) => {
                const docRef = doc(db, "leads", id);
                await updateDoc(docRef, { admins: arrayRemove(email) });
            })
        );

        if (user.id === email) {
            setSourceLeads((prev) => prev.filter((lead) => !rowSelectionModel.includes(lead.id)));
        }

        setLoading(false);

        toast.success("Admin removed successfully");
    };

    const handleChooseLabel = async () => {
        const newLabelsList = type === "SOURCE_LEADS" ? labelsListSource : labelsListAssigned;

        const response = await list(newLabelsList, "Choose Label", "name", "label", false, true, "count");

        if (!response) return;

        setLabels((prev) => {
            const newLabels = prev ? [...prev, response.name] : [response.name];
            return newLabels;
        });
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <Loading loading={loading} />
                <GridFlexBox fs md={5} xs={12}>
                    <Tabs value={leadsType} onChange={handleChangeType}>
                        <Tab label="Source Leads" />
                        <Tab label="Assigned Leads" />
                    </Tabs>
                    {type === "ASSIGNED_LEADS" && (
                        <IconButton onClick={handleFixAssignedLeads}>
                            <Icon>build</Icon>
                        </IconButton>
                    )}
                    {type === "SOURCE_LEADS" && (
                        <Box display="flex" alignItems={"center"} gap={"4px"}>
                            <Checkbox
                                checked={hideAssignedLeads}
                                onChange={(e) => setHideAssignedLeads(e.target.checked)}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                            <Typography>Hide assigned leads</Typography>
                        </Box>
                    )}
                    <IconButton onClick={handleClickRefresh}>
                        <Icon>refresh</Icon>
                    </IconButton>
                </GridFlexBox>
                <GridFlexBox md={7} xs={12} gap={1}>
                    <Box display="flex" alignItems={"center"} gap={1}>
                        <IconButton onClick={handleClickBack}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                        <Typography>{year}</Typography>
                        <IconButton onClick={handleClickForward}>
                            <Icon>arrow_forward</Icon>
                        </IconButton>
                    </Box>
                    <Typography>{`No. of leads: ${
                        leads?.length ? formatNumber(leads.length, 0) : 0
                    }`}</Typography>
                    {/* <Button sx={{ marginRight: "8px", marginLeft: "8px" }} onClick={handleSetLoadAll}>
                        Load All
                    </Button> */}
                    <FormControl fullWidth>
                        <InputLabel size="small">Page</InputLabel>
                        <Select value={currentPageId} label="Page" onChange={handleChangePage} size="small">
                            {pages.map((page) => (
                                <MenuItem value={page.id} key={page.id}>
                                    {page.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridFlexBox>
                <GridFlexBox md={12} xs={12} gap={1} fs>
                    <Box my={1}>
                        <Button variant="contained" color="info" size="small" onClick={handleChooseLabel}>
                            Filter by Label
                        </Button>
                    </Box>
                    {!arrayIsEmpty(labels) && (
                        <Box display="flex" gap={1} my={1}>
                            {labels.map((label) => (
                                <Chip
                                    key={label}
                                    label={label}
                                    onDelete={() => {
                                        setLabels((prev) => prev.filter((l) => l !== label));
                                    }}
                                />
                            ))}
                        </Box>
                    )}
                </GridFlexBox>
                <GridFlexBox fs>
                    <Paper sx={{ height: "80vh", width: "100%", overflowY: "auto" }}>
                        <DataGrid
                            rows={leads ? leads : []}
                            columns={columns}
                            slots={{
                                toolbar: () => (
                                    <CustomToolbar
                                        onDelete={deleteLeads}
                                        onAssign={handleAssignLeads}
                                        disabled={rowSelectionModel.length === 0}
                                        hideAssignedLeads={hideAssignedLeads}
                                        onRemoveAdmin={handleRemoveAdmin}
                                        type={type}
                                    />
                                ),
                                noRowsOverlay: () => CustomNoRowsOverlay({ pageId: currentPageId }),
                            }}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            initialState={{}}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            rowsPerPageOptions={[5, 10, 20, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                            processRowUpdate={(updatedRow) => handleProcessRowUpdate(updatedRow)}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                        />
                    </Paper>
                </GridFlexBox>
                <GridFlexBox fe gap={1}>
                    <Typography>Page: </Typography>
                    <Select value={paginationModel.page} onChange={handleChangePaginationModel} size="small">
                        {/* map total number of pages */}
                        {Array.from({ length: totalPages }, (_, i) => (
                            <MenuItem key={i} value={i}>
                                {i + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </GridFlexBox>
            </GridContainer>
            <FullDialog
                open={openCard}
                handleClose={() => setOpenCard(false)}
                title={"Lead Card"}
                Component={
                    <LeadCardPage
                        lead={currentLead}
                        handleClose={() => setOpenCard(false)}
                        leadsMode={type}
                        handleUpdateLead={handleUpdateLead}
                    />
                }
            />
            <FullDialog
                open={openLabel}
                handleClose={() => setOpenLabel(false)}
                title={"Labels"}
                Component={
                    <ManageLabels
                        lead={currentLead}
                        setLead={setCurrentLead}
                        handleClose={() => setOpenLabel(false)}
                        setLeads={type === "SOURCE_LEADS" ? setSourceLeads : setAssignedLeads}
                    />
                }
            />
        </Container>
    );
}

function CustomToolbar({ onDelete, onAssign, disabled, hideAssignedLeads, type, onRemoveAdmin }) {
    return (
        <GridToolbarContainer>
            <GridToolbar
                csvOptions={{
                    delimiter: ",",
                    utf8WithBom: true,
                }}
            />
            <GridToolbarQuickFilter />
            <Box display="flex" alignItems={"center"} pt="4px">
                <Button
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={onDelete}
                    disabled={disabled}
                    size="small"
                >
                    Delete
                </Button>
                <Button
                    startIcon={<AssignmentIndOutlinedIcon />}
                    color="primary"
                    onClick={onAssign}
                    disabled={disabled || !hideAssignedLeads}
                    size="small"
                >
                    Assign
                </Button>
                <Button
                    startIcon={<PersonRemoveIcon />}
                    color="primary"
                    onClick={onRemoveAdmin}
                    disabled={type === "ASSIGNED_LEADS" || disabled}
                    size="small"
                >
                    Remove Admin
                </Button>
            </Box>
        </GridToolbarContainer>
    );
}
