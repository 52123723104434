import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function updateLead(lead) {
    console.log(lead);
    try {
        const docRef = doc(db, "leads", lead.id);
        await setDoc(docRef, lead, { merge: true });
        console.log(lead.id, " updated in Database");
    } catch (error) {
        console.log(error);
    }
}
