import { Box, Button, Dialog, DialogTitle, Divider, MenuItem, Select, Switch } from "@mui/material";
import { Name } from "../../themes/themes";
import { useEffect, useState, useMemo } from "react";
import { db } from "../../firebase/firebase-utils";
import {getDoc,doc } from "firebase/firestore";

export default function SettingsDialog({
    open,
    setOpen,
    currentFlow,
    model,
    setModel,
    handleChangeAppointment,
    handleChangeAppointmentOverlapping,
    handleChangeDisableAI,
    handleChangeSaveLead,
    handleChangeSendAudio,
    handleUnresolveTelegramNotify,
    handleChangeFileAnalysis,
    handleChangeIntentionBased,
    user,
    handleChangeAiDelayReply,
    handleChangeChatHistoryLength,
    voiceTypes,
    handleChangeVoiceType,
}) {
    const [voiceList, setVoiceList] = useState([]);
    const handleClose = () => {
        setOpen(false);
    };

    useMemo(() => {
        const getVoices = async () => {
            try {
                const voiceNameDoc = doc(db, "settings", "voiceName");
                const docSnap = await getDoc(voiceNameDoc);

                if (docSnap.exists()) {
                    const voices = docSnap.data()?.voiceList || [];
                    setVoiceList(voices);
                }
            } catch (error) {
                console.error("Error fetching voice list:", error);
                setVoiceList([]); 
            }
        };

        getVoices();
        return getVoices;
    }, []);

    return (
        <>
            <Button
                variant="contained"
                size={"small"}
                onClick={() => setOpen(true)}
                disabled={!currentFlow.id}
            >
                Settings
            </Button>
            <Dialog
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: {
                            xs: "95vw", // width for extra-small screens
                            sm: "300px", // width for medium screens and above
                        },
                    },
                }}
            >
                <DialogTitle>Whatsapp Flow Settings</DialogTitle>
                <Box m={"16px"}>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>AI Model</Name>
                        <Select
                            disabled={!currentFlow.id}
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={"gpt-4o-mini"}>gpt-4o-mini</MenuItem>
                            <MenuItem value={"gemini-1.5-flash"}>gemini-1.5-flash</MenuItem>
                            {/* <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem> */}
                            {/* <MenuItem value={"claude-3-haiku-20240307"}>Claude 3 Haiku</MenuItem> */}
                            <Divider />
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"gpt-4o"}>gpt-4o</MenuItem>
                            )}
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"gemini-1.5-pro"}>gemini-1.5-pro</MenuItem>
                            )}
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"claude-3-5-sonnet-20240620"}>Claude 3.5 Sonnet</MenuItem>
                            )}
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"claude-3-5-haiku-20241022"}>Claude 3.5 Haiku</MenuItem>
                            )}
                        </Select>
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Appointment</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeAppointment}
                            checked={currentFlow.appointment === undefined ? false : currentFlow.appointment}
                        />
                    </Box>
                    {currentFlow.appointment && (
                        <Box display="flex" justifyContent={"space-between"}>
                            <Name>Appointment allow overlapping</Name>
                            <Switch
                                disabled={currentFlow.id ? false : true}
                                onChange={handleChangeAppointmentOverlapping}
                                checked={
                                    currentFlow.appOverlapping === undefined
                                        ? false
                                        : currentFlow.appOverlapping
                                }
                            />
                        </Box>
                    )}
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Enable AI</Name>
                        <Switch
                            disabled={currentFlow?.id ? false : true}
                            onChange={(e) => {
                                handleChangeDisableAI(e);
                                if (!e.target.checked && currentFlow?.fileAnalysis) {
                                    handleChangeFileAnalysis(false);
                                }
                            }}
                            checked={currentFlow?.disableAI === undefined ? true : !currentFlow?.disableAI}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Enable File Analysis</Name>
                        <Switch
                            disabled={currentFlow?.id ? (currentFlow?.disableAI ? true : false) : true}
                            onChange={handleChangeFileAnalysis}
                            checked={
                                currentFlow?.disableAI
                                    ? false
                                    : currentFlow?.fileAnalysis === undefined
                                    ? false
                                    : currentFlow?.fileAnalysis
                            }
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Save Leads</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeSaveLead}
                            checked={currentFlow.saveLead === undefined ? true : currentFlow.saveLead}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Voice Reply</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeSendAudio}
                            checked={currentFlow.sendAudio === undefined ? false : currentFlow.sendAudio}
                        />
                    </Box>
                    {currentFlow?.sendAudio && (
                        <Box display="flex" justifyContent={"space-between"}>
                            <Name>Voice Type</Name>
                            <Select
                                disabled={!currentFlow.id}
                                value={currentFlow?.voiceType || (voiceList[0] || '')}
                                onChange={(e) => handleChangeVoiceType(e.target.value)}
                                size="small"
                            >
                                {(voiceList.length > 0 ? voiceList : voiceTypes || []).map((voice) => (
                                    <MenuItem key={voice} value={voice}>
                                        {voice}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    )}

                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>AI Delay Reply</Name>
                        <Select
                            disabled={!currentFlow.id}
                            value={currentFlow.aiDelayReply || "0 second"}
                            onChange={(e) => handleChangeAiDelayReply(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={0}>0 second</MenuItem>
                            <MenuItem value={3}>3 seconds</MenuItem>
                            <MenuItem value={6}>6 seconds</MenuItem>
                            <MenuItem value={9}>9 seconds</MenuItem>
                        </Select>
                    </Box>

                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Unresolve Queries Telegram Notification</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleUnresolveTelegramNotify}
                            checked={
                                currentFlow.sendTelegramNotificationUnresolvedQueries === undefined
                                    ? false
                                    : currentFlow.sendTelegramNotificationUnresolvedQueries
                            }
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Chat History Length</Name>
                        <Select
                            disabled={!currentFlow.id}
                            value={currentFlow?.chatHistoryLength || 8}
                            onChange={(e) => handleChangeChatHistoryLength(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select>
                    </Box>
                    {user.role === "Super Admin" && (
                        <Box display="flex" justifyContent={"space-between"}>
                            <Name>Enable Intention Flow</Name>
                            <Switch
                                onChange={handleChangeIntentionBased}
                                checked={
                                    currentFlow.intentionBased === undefined
                                        ? false
                                        : currentFlow.intentionBased
                                }
                            />
                        </Box>
                    )}
                </Box>
            </Dialog>
        </>
    );
}
