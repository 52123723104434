import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

// Staging database
const firebaseConfig_staging = {
    apiKey: process.env.REACT_APP_FIREBASE_STAGING_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_STAGING_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_STAGING_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STAGING_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_STAGING_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_STAGING_APP_ID,
};

// Production database
const firebaseConfig_production = {
    apiKey: process.env.REACT_APP_FIREBASE_PRODUCTION_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_PRODUCTION_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PRODUCTION_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_PRODUCTION_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_PRODUCTION_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_PRODUCTION_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_PRODUCTION_MEASUREMENT_ID,
};

const stage = process.env.REACT_APP_STAGE;

let firebaseConfig;
if (stage === "production") {
    firebaseConfig = firebaseConfig_production;
} else {
    firebaseConfig = firebaseConfig_staging;
}

const app = initializeApp(firebaseConfig);
console.log("Using firebaseConfig: ", stage);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "asia-southeast1");
export const dbRT = getDatabase();
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

const vertexAI = getVertexAI(app);
const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash-preview-0514" });

export { model };

logEvent(analytics, "notification_received");

export default app;
