import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { collection, doc, getDoc, getDocs, limit, query, where } from "firebase/firestore";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase/firebase-utils";
import signUp from "../functions/signUp";
import { COPYRIGHT_LINK, SECRET_CODE, WEBSITE_NAME } from "../settings/settings";
import { GridFlexBox, TextFieldC } from "../themes/themes";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import { notification } from "../utils-functions/notification";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href={COPYRIGHT_LINK}>
                {WEBSITE_NAME}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default function SignUpPage() {
    const { setUser, setAuthUser } = React.useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const { openNotification } = React.useContext(NotificationContext);
    const [signupReferral, setSignupReferral] = useState({
        shortId: "",
        fullId: "",
    });
    const navigate = useNavigate();
    const { referral } = useParams();
    const [openDialog, setOpenDialog] = useState(false);

    //The close handler of the dialog, when affiliate link received
    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate("/");
    };

    const ref = React.useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const fullName = data.get("fullName");
        const email = data.get("email");
        const email2 = data.get("email2");
        const phone = data.get("phone");
        const password = data.get("password");
        const secretCode = data.get("secretCode");

        if (email.toLocaleLowerCase().trim() !== email2.toLocaleLowerCase().trim()) {
            toast.error("Emails do not match");
            return;
        }

        if (referral) {
            console.log("Referral code sign up");
        } else {
            //if doesnt have referral code in url, then need to check with the default one
            if (secretCode !== SECRET_CODE) {
                notification(
                    "Secret code is not correct",
                    "Please get the secret code from your referral",
                    "warning"
                );
                return;
            }
        }

        setLoading(true);

        const user = await signUp(fullName, email, phone, password, setUser, setAuthUser, signupReferral);

        if (user) {
            console.log(user);
            openNotification("Signed in successfully", "success");
        }

        setLoading(false);

        if (user) navigate("/stripe-checkout-page");
    };

    const param = useParams();

    useEffect(() => {
        ref.current.value = param.referral || "";
        const checkIfReferralExist = async (referral) => {
            console.log("checkIfReferralExist: init ", referral);

            //The first checking should be the mainstream way, to check with the referralCode field
            const usersColRef = collection(db, "users");
            const q = query(usersColRef, where("referralCode", "==", referral), limit(1));
            const usersDocRef = doc(db, "users", referral);

            try {
                const usersSnapshot = await getDocs(q);

                if (!usersSnapshot.empty && usersSnapshot.size === 1) {
                    console.log("checkIfReferralExist: User= ", usersSnapshot.docs[0].data());
                    const referralObj = { ...usersSnapshot.docs[0].data(), id: usersSnapshot.docs[0].id };
                    const shortId = referralObj.referralCode;
                    setSignupReferral({
                        shortId: shortId,
                        fullId: referralObj.id,
                    });
                    console.log(
                        `checkIfReferralExist: Referral code "${referral}" is valid, the id is ${referralObj.id}`
                    );
                } else {
                    //This is the legacy checking where initially the param used for the referral code in url is the user's id
                    const usersSnapshot = await getDoc(usersDocRef);
                    const referralObj = mapDocSnapshot(usersSnapshot);

                    if (usersSnapshot.exists()) {
                        console.log("checkIfReferralExist: Legacy User= ", referralObj);
                        const shortId = referralObj.id;
                        setSignupReferral({
                            shortId: shortId,
                            fullId: referralObj.id,
                        });
                        console.log(
                            `checkIfReferralExist: Referral code "${referral}" is valid, the id is ${referralObj.id}`
                        );
                    } else {
                        console.log(`checkIfReferralExist: Referral code "${referral}" is not valid.`);
                        setOpenDialog(true);
                    }
                }
            } catch (err) {
                console.log("checkIfReferralExist: Error= ", err.message);
            }
        };

        if (referral) {
            console.log("SignUpPage: referral= ", referral);
            //Check if the referral exist in the system or not.
            checkIfReferralExist(referral);
        } else {
            console.log("SignUpPage: No parameter provided");
        }
    }, [referral]);

    return (
        <Container component="main" maxWidth="xs">
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Invalid Affiliate Link</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sorry the affiliate link seems to be invalid, you will be navigated back to the home
                        page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Confirm</Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container>
                            <TextFieldC name="fullName" label="Full Name" autoFocus m1 />
                            <TextFieldC name="email" label="Email Address" email />
                            <TextField
                                name="email2"
                                label="Reenter your email"
                                fullWidth
                                email
                                required
                                onPaste={(e) => {
                                    e.preventDefault();
                                }}
                            />
                            <TextFieldC name="phone" label="Mobile Phone" />
                            <TextFieldC name="password" label="Password" password />
                        </Grid>
                        <Grid container>
                            <GridFlexBox>
                                <TextField
                                    name="secretCode"
                                    label="Secret Code"
                                    placeholder="djc123"
                                    fullWidth
                                    inputRef={ref}
                                    required
                                    disabled={referral ? true : false}
                                />
                            </GridFlexBox>
                        </Grid>

                        {loading && <LinearProgress />}
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign Up
                        </Button>
                    </form>

                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/sign-in" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}
