import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    onSnapshot,
    orderBy,
    query,
    Timestamp,
    updateDoc,
} from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db, storage } from "../../firebase/firebase-utils";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";

import { useLocation, useNavigate } from "react-router-dom";
import { ItemComponent } from "./ItemComponent";
import PromptsPage from "./PromptsPage";
import toast from "react-hot-toast";

export type User = {
    id: string;
    displayName: string;
    companyIds?: string[];
};

export type Setting = {
    date: Timestamp;
    id: string;
    description: string;
    subCollectionName: string;
    title: string;
} | null;

// Define the Comment interface with expected fields
export type Comment = {
    id: string; // Firestore document ID
    text: string; // The comment text
    createdAt: Date; // The timestamp when the comment was created
    createdBy: string;
    createdByName: string;
    files?: { name: string; downloadURL: string }[];
    // Add any other fields you expect in a comment
};

export type Case = {
    id: string;
    date: Date;
    prospectName: string;
    prospectPhone: string;
    prospectEmail: string;
    productId: string;
    value: string;
    agentIds: string[];
    agentNames: string[];
    editorIds?: string[];
    editorNames?: string[];
    percentages: string[];
    customValues: string[];
    customRemarks: string[];
    remark: string;
    companyId: string;
    createdBy: string;
    created: Date;
    profilePicUrl?: string;
    admins: string[];
    viewerIds: string[]; // Optional viewerIds field
    viewerNames: string[]; // Optional viewerNames field
    caseId?: string[];
    latestCompanyId: string;

    customFields?: ICustomField[];
} | null;

export type Selection = "VIEW_CASES";

export type ICustomField = {
    label: string;
    value: string;
    type: string | number;
};

export default function SystemPage(): React.JSX.Element {
    const [settings, setSettings] = useState<Setting[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [columnWidths, setColumnWidths] = useState<number[]>([30, 40, 30]);
    const [columnDisplay, setColumnDisplay] = useState<number[]>([1, 1, 1]);
    const [currentColumn, setCurrentColumn] = useState<number>(0);

    const [currentItemId, setCurrentItemId] = useState<string>("");
    const [currentItem, setCurrentItem] = useState<Setting>(null);

    const [subItems, setSubItems] = useState<any[]>([]);

    const { user } = useContext(AuthContext) as { user: User };
    const input = useInput();
    const colors = useColors();
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const [inputText, setInputText] = useState<string>("");

    const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const [editItem, setEditItem] = useState<any>(null);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

    const [newComment, setNewComment] = useState<string>("");

    const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<{ file: any; index: number; commentId: string } | null>(
        null
    );

    const windowSize = useWindowSize();

    const navigate = useNavigate();
    const location = useLocation();

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (isMobile) {
            switch (currentColumn) {
                case 0: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
                case 1: {
                    setColumnDisplay([0, 1, 0]);
                    setColumnWidths([0, 100, 0]);
                    break;
                }
                case 2: {
                    setColumnDisplay([0, 0, 1]);
                    setColumnWidths([0, 0, 100]);
                    break;
                }
                default: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
            }
        } else {
            setColumnDisplay([1, 1, 1]);
            setColumnWidths([30, 40, 30]);
        }
    }, [isMobile, currentColumn]);

    // Load settings on user login
    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "settings");

        const q = query(collectionRef, orderBy("date", "desc"));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            let settings = mapSnapshotTS<Setting>(querySnapshot);

            console.log("Settings", settings);

            setSettings(settings);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!currentItemId) {
            setCurrentItem(null);

            return;
        }

        const docRef = doc(db, "settings", currentItemId);
        const unsubscribe = onSnapshot(docRef, (doc: any) => {
            const data = mapDocSnapshot(doc);

            setCurrentItem(data);
            console.log("Data ", data);
        });

        return unsubscribe;
    }, [currentItemId]);

    useEffect(() => {
        if (!currentItemId || !currentItem) return; // Prevent running if there's no case selected

        const subCollectionName = currentItem.subCollectionName;
        if (!subCollectionName) {
            toast.error("Subcollection name not found.");
            setSubItems([]);
            return;
        }

        const collectionRef = collection(db, "settings", currentItemId, subCollectionName);
        const q = query(collectionRef, orderBy("date", "desc")); // Ensure this field exists

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const subItems = mapSnapshotTS(snapshot);
            console.log("Sub Items", subItems);
            setSubItems(subItems);
        });

        return unsubscribe; // Cleanup subscription on unmount
    }, [currentItemId, currentItem]); // Add currentCaseId as a dependency

    const handleConfirmEdit = async () => {
        if (editItem) {
            try {
                const docRef = doc(db, "cases", currentItemId, "comments", editItem.id);
                await updateDoc(docRef, {
                    text: newComment,
                });
                setEditDialogOpen(false);
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    const handleCancelEdit = () => {
        setEditItem(null);
        setEditDialogOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (deleteItemId) {
            try {
                const docRef = doc(db, "cases", currentItemId, "comments", deleteItemId);

                // Step 1: Get the comment document to retrieve file paths
                const commentDoc = await getDoc(docRef);
                if (commentDoc.exists()) {
                    const commentData = commentDoc.data();

                    // Step 2: Delete files from Firebase Storage
                    if (commentData.files && Array.isArray(commentData.files)) {
                        await Promise.all(
                            commentData.files.map(async (file) => {
                                console.log("File path:", file.path); // Log the file path to check it
                                const fileRef = ref(storage, file.path); // Use the stored file path
                                await deleteObject(fileRef); // Delete the file from Firebase Storage
                            })
                        );
                    }

                    // Step 3: Delete the comment document from Firestore
                    await deleteDoc(docRef);

                    // Step 4: Close the dialog and reset the state
                    setDeleteDialogOpen(false);
                    setDeleteItemId(null); // Reset after deletion
                } else {
                    console.error("Document does not exist.");
                }
            } catch (error) {
                console.error("Error deleting document and files: ", error);
            }
        }
    };

    const handleCancelDelete = () => {
        setDeleteItemId(null);
        setDeleteDialogOpen(false);
    };

    const deleteUploadFile = (file: any, index: number, commentId: string) => {
        setFileToDelete({ file, index, commentId });
        setDeleteFileDialogOpen(true);
    };

    const handleConfirmDeleteFile = async () => {
        console.log("Confirm delete file clicked.");
        if (fileToDelete) {
            const { file, index, commentId } = fileToDelete;
            try {
                const fileRef = ref(storage, file.path);
                await deleteObject(fileRef);

                const commentRef = doc(db, "cases", currentItemId, "comments", commentId);
                const commentDoc = await getDoc(commentRef);

                if (commentDoc.exists()) {
                    const commentData = commentDoc.data();
                    const updatedFiles = commentData.files.filter(
                        (_: any, fileIndex: number) => fileIndex !== index
                    );
                    await updateDoc(commentRef, { files: updatedFiles });
                    console.log("File deleted and Firestore updated.");
                }
            } catch (error) {
                console.error("Error deleting file: ", error);
            } finally {
                setDeleteFileDialogOpen(false);
                setFileToDelete(null);
            }
        } else {
            console.log("No file to delete.");
        }
    };

    const handleCancelDeleteFile = () => {
        setFileToDelete(null);
        setDeleteFileDialogOpen(false);
    };

    return (
        <Box display="flex" width={"100vw"} height={`calc(100vh - 55px)`}>
            {/* First column */}
            <Box
                display={columnDisplay[0] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[0] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    p={1}
                    py={2}
                    width={"100%"}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box display="flex" alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(1)}>
                                <Icon>settings</Icon>
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection={"column"}
                    width="100%"
                    sx={{ overflowY: "auto" }}
                    // onScroll={handleScroll} // Add the scroll handler here
                >
                    {settings.map((setting) => (
                        <Box key={setting?.id} width={"100%"}>
                            <ItemComponent
                                item={setting}
                                selected={currentItemId === setting?.id}
                                handleClick={() => {
                                    setCurrentColumn(1);
                                    setCurrentItemId(setting?.id || "");
                                    console.log(setting);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Second column */}
            <Box
                display={columnDisplay[1] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[1] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(0)}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        )}
                        <Avatar />
                        <Typography>{currentItem?.title || ""}</Typography>
                    </Box>
                    <Box>
                        {/* Button to enter third column */}
                        {isMobile && (
                            <Box display="flex" justifyContent="center" p={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setCurrentItemId(currentItemId);
                                        setCurrentColumn(2);
                                    }}
                                    sx={{ width: "100%" }} // Make the button full width
                                >
                                    View Details
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    width="100%"
                    height="100%"
                    sx={{
                        backgroundSize: "cover", // to cover the entire Box area
                        backgroundPosition: "center",
                        overflowY: "auto",
                    }}
                >
                    {currentItemId === "systemPrompts" && (
                        <Box p={1} width={"100%"}>
                            <PromptsPage prompts={subItems} collectionName="systemPrompts" />
                        </Box>
                    )}

                    {currentItemId === "userPrompts" && (
                        <Box p={1} width={"100%"}>
                            <PromptsPage prompts={subItems} collectionName="userPrompts" />
                        </Box>
                    )}
                </Box>
            </Box>

            {/* Third column */}
            <Box
                display={currentItemId ? "flex" : "none"} // Check for both company and case selection
                flexDirection={"column"}
                width={columnWidths[2] + "%"}
                height={"100%"}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(1)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    <Typography>Details</Typography>
                </Box>

                <Box></Box>
            </Box>

            {/* Delete confirmation dialog */}
            <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this item?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editDialogOpen} onClose={handleCancelEdit}>
                <DialogTitle>Edit Purchase</DialogTitle>
                <DialogContent sx={{ maxHeight: "500px" }}>
                    <Box display="flex" flexDirection="column" gap="30px" sx={{ marginTop: "10px" }}>
                        <Box>
                            <Typography
                                sx={{
                                    width: "200px",
                                    fontWeight: "bold",
                                    textAlign: "left",
                                }}
                            >
                                New Comment
                            </Typography>

                            <TextField
                                size="small"
                                sx={{ width: "300px" }}
                                value={newComment}
                                multiline
                                rows={4}
                                onChange={(e) => setNewComment(e.target.value)}
                            ></TextField>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelEdit}>Cancel</Button>
                    <Button onClick={handleConfirmEdit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteFileDialogOpen} onClose={handleCancelDeleteFile}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this item?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDeleteFile}>Cancel</Button>
                    <Button onClick={handleConfirmDeleteFile} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
