import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateDisableAI(user, currentFlow, disableAI) {
    try {
        if (!currentFlow?.id) return;
        
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        
        // When disabling AI (disableAI = true), also set fileAnalysis to false
        if (disableAI) {
            await updateDoc(docRef, {
                disableAI,
                fileAnalysis: false
            });
        } else {
            await updateDoc(docRef, {
                disableAI
            });
        }
    } catch (err) {
        console.error("Error updating AI status:", err);
    }
}
